import React from 'react'
import {
  Box,
  Typography,
  useTheme,
  useMediaQuery,
  Container,
  Button,
} from '@mui/material'
import { getTextDirection } from '../../../utils/getTextDirection'
import { useTranslation } from 'react-i18next'
import PricesCards from './PricesCards'
import { keyframes } from '@mui/system'
import { useNavigate } from 'react-router-dom'
export default function WhyUs() {
  const theme = useTheme()
  const isMobile = useMediaQuery(theme.breakpoints.down('md')) // Adjust the breakpoint
  const { t, i18n } = useTranslation()
  const isArabic = i18n.language === 'ar'
  const direction = getTextDirection(i18n.language)
  const spin = keyframes`
  0% {
    transform: rotate(0deg);
  }
  10% {
    transform: rotate(4deg);
  }
  20% {
    transform: rotate(7deg);
  }
  50% {
    transform: rotate(0deg);
  }
  70% {
    transform: rotate(-4deg);
  }
  90% {
    transform: rotate(-7deg);
  }
  100% {
    transform: rotate(0deg);
  }
`
  const navigate = useNavigate()

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column', // Stack on mobile
        alignItems: 'center',
        justifyContent: 'space-between',
        mb: '5%',
      }}
    >
      <Box
        sx={{
          mt: 2,
          flex: 1,
          padding: { xs: '1rem', md: '1rem', lg: '3rem' }, //isMobile ? '1rem' : '3rem',

          alignItems: 'center',
        }}
      >
        <Box align="center">
          <Typography
            component="span"
            variant="h2"
            sx={{
              // Highlight background
              color: 'primary.contrastText', // Highlight text color
              padding: '0 0.5rem', // Add padding for emphasis
              align: 'center',
            }}
          >
            <Typography
              component="span"
              variant="h2"
              sx={{
                backgroundColor: 'secondary.main',
                color: 'primary.contrastText', // Highlight text color
                padding: '0 0.5rem',
                align: 'center',
                mb: '0', // Add padding for emphasis
                // align: direction === 'rtl' ? 'right' : 'left',
                // fontSize: { sm: '2.5rem', md: '3rem', lg: '4rem' },
              }}
            >
              {t('einvoice.pricing')}
            </Typography>{' '}
            {t('einvoice.plans')}
          </Typography>
          {/* {t('home.business')} */}
          <Typography
            variant="body1"
            mt="3%"
            sx={{
              align: 'center',
              textAlign: 'center',
            }}
          >
            {/* {t('home.paragraph')} */}
            {t('einvoice.pricing_para')}
          </Typography>
        </Box>
      </Box>
      <PricesCards />
      <Typography
        variant="body1"
        mt="3%"
        sx={{ align: 'center', textAlign: 'center' }}
      >
        {/* {t('home.paragraph')} */} {t('einvoice.plans_para')}
      </Typography>
      <Button
        m={5}
        variant="contained"
        sx={{
          backgroundColor: 'secondary.main',
          mt: 10,
          animation: `${spin} 0.5s infinite ease`,
          ease: 'bounce',
        }}
        size="large"
        onClick={() => navigate('/contactus')}
      >
        <Typography variant="h4"> {t('home.contact_us')}</Typography>
      </Button>
    </Box>
  )
}
