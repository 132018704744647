import { Box, Typography } from '@mui/material'
import { useTheme, useMediaQuery } from '@mui/material'
import { useTranslation } from 'react-i18next'
import PhoneIcon from '@mui/icons-material/Phone'
import LocationOnIcon from '@mui/icons-material/LocationOn'
import EmailIcon from '@mui/icons-material/Email'
import MapComponent from './MapComponent'
import BackgroundCircle from '../../common/BackgroundCircle'
import useDirection from '../../../hooks/useDirection'
export default function ContactUs() {
  const theme = useTheme()
  //const isMobile = useMediaQuery(theme.breakpoints.down('md')) // Adjust the breakpoint
  const { t, i18n } = useTranslation()
  const isArabic = i18n.language === 'ar'
  const direction = useDirection()

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: {
          md: 'column',
          lg: 'row',
        },
        // isMobile ? 'column' : 'row', // Stack on mobile
        alignItems: 'center',
        justifyContent: 'space-between',
        padding: {
          md: '2rem',
          lg: '4rem',
        },
        // isMobile ? '2rem' : '4rem',
      }}
    >
      <BackgroundCircle Nb={1} position={{ top: '0vh', left: '5vw' }} />
      <BackgroundCircle Nb={0} position={{ top: '-60vh', left: '50vw' }} />
      <BackgroundCircle Nb={1} position={{ top: '10vh', right: '-100vw' }} />
      {/* Left Column: Text */}
      <Box
        sx={{
          flex: 1,
          paddingRight: {
            md: '0',
            lg: '2rem',
          },
          mr: 10,
          // isMobile ? '0' : '2rem', // Add padding for larger screens
          // textAlign: {
          //   md: 'center',
          //   // lg: 'left',
          // },
          // isMobile ? 'center' : 'left', // Center text on mobile
        }}
      >
        <Typography variant="h4" component="h1" sx={{ mb: 2 }}>
          {t('home.contact_us')}
        </Typography>
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column', // Stack vertically

            // isMobile ? 'center' : 'flex-start', // Center on mobile, align left on larger screens
            mb: 2,
          }}
        >
          <Box sx={{ display: 'flex', mb: 1, direction: direction }}>
            <LocationOnIcon
              fontSize="small"
              sx={{ color: 'secondary.main', mt: '2px' }}
            />
            <Typography variant="body2" sx={{ mx: 1 }}>
              {t('footer.locations.jeddah')}
            </Typography>
          </Box>
          <Typography variant="body2" sx={{ mt: 1 }}>
            <EmailIcon
              fontSize="small"
              sx={{ color: 'secondary.main', mt: '2px' }}
            />{' '}
            {t('footer.info.email')}
          </Typography>
          <Typography variant="body2" sx={{ mt: 1 }}>
            <PhoneIcon
              fontSize="small"
              sx={{ color: 'secondary.main', mt: '2px' }}
            />{' '}
            {t('footer.info.phone')} - {t('footer.info.phone')}
          </Typography>
        </Box>
      </Box>

      {/* Right Column: Map Component */}
      <Box
        sx={{
          flex: 1,
          display: 'flex',
          justifyContent: 'center',
          width: '100%', // Ensure full width
          height: {
            md: '300px',
            lg: '500px',
          },
          // isMobile ? '300px' : '500px', // Set minimum height for mobile and larger screens
        }}
      >
        <MapComponent /> {/* Display the map here */}
      </Box>
    </Box>
  )
}
