import React from 'react'
import { MapContainer, TileLayer, Marker, Popup } from 'react-leaflet'
//import icon from 'leaflet/dist/images/marker-icon'
import 'leaflet/dist/leaflet.css'
import { Icon } from 'leaflet'
import locationIcon from '../../../assests/img/icons/locationIcon.svg'
import { useTranslation } from 'react-i18next'

const MapComponent = () => {
  const { t, i18n } = useTranslation()
  const isArabic = i18n.language === 'ar'
  // Coordinates for the map center and the marker
  const position = [21.58354379109045, 39.17637471776596] // London coordinates (replace with your location)
  const myIcon = new Icon({
    iconUrl: locationIcon,
    iconSize: [32, 32],
  })
  return (
    <MapContainer
      center={position}
      zoom={17}
      style={{ height: '50vh', width: '100%' }} // Set the map size
    >
      {/* Tile layer using OpenStreetMap tiles */}
      <TileLayer
        url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
        attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
      />
      {/* Marker at the center */}
      <Marker position={position} icon={myIcon}>
        <Popup> {t('home.this_location')}</Popup>
      </Marker>
    </MapContainer>
  )
}

export default MapComponent
