import React, { useState } from 'react'
import {
  Box,
  Card,
  CardContent,
  Typography,
  IconButton,
  Modal,
  Button,
  useTheme,
  Container,
} from '@mui/material'
import { Info as InfoIcon } from '@mui/icons-material'
import BadgeIcon from '../../../assests/img/products/badgeIcon.svg'
import HappyIcon from '../../../assests/img/products/happyIcon.svg'
import CheckIcon from '../../../assests/img/products/calendar.svg'
import NewsIcon from '../../../assests/img/products/news.svg'
import { useTranslation } from 'react-i18next'

export default function ERPSoftwareSolutionCards() {
  const theme = useTheme()
  const rightLocation = theme.custom.rightLocation
  const whiteShades = theme.palette.custom.whiteShades
  const { t, i18n } = useTranslation()
  const isArabic = i18n.language === 'ar'

  const papersContent = [
    {
      title: t('products.licensed_customers_title'),
      description: t('products.licensed_customers_para'),
      icon: BadgeIcon,
      color: '#559f82',
    },
    {
      title: t('products.websites_and_apps_title'),
      description: t('products.websites_and_apps_para'),
      icon: NewsIcon,
      color: '#57c886',
    },
    {
      title: t('products.customer_retention_rate_title'),
      description: t('products.customer_retention_rate_para'),
      icon: CheckIcon,
      color: '#056741',
    },
    {
      title: t('products.years_of_growth_title'),
      description: t('products.years_of_growth_para'),
      icon: HappyIcon,
      color: '#e2d40a',
    },
  ]
  return (
    <Container sx={{ padding: 1 }}>
      <Box
        sx={{
          padding: 1,
          boxSizing: 'border-box',
          display: 'grid',
          gridTemplateColumns: {
            xs: '2fr 2fr',
            md: '2fr 2fr',
            lg: '2fr 2fr 2fr',
          },
          gap: 2,
          maxWidth: '90vw',
          alignItems: 'center', // Center items vertically
          justifyContent: 'center', // Center items horizontally
        }}
      >
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center', // Center items vertically
            justifyContent: 'center', // Center items horizontally
          }}
        >
          {/* Second row: 3 cards evenly split */}
          {[0, 1].map(index => (
            <Card
              key={index}
              sx={{
                height: '100%',
                borderRadius: '16px',
                backgroundColor:
                  whiteShades[Math.floor(Math.random() * whiteShades.length)],
                display: 'flex',
                flexDirection: 'column',
                boxShadow: 3,
                overflow: 'hidden',
                position: 'relative', // Set position relative for absolute children

                mb: { xs: 4, md: 4, lg: 4, xl: 4 },
                mt: { xs: 4, md: 4, lg: 4, xl: 4 },
                mr: { xs: 0.5, md: 1, lg: 4, xl: 4 },
                ml: { xs: 0.5, md: 4, lg: 4, xl: 4 },

                top: { xs: '0%', md: '-10%', lg: '-10%', xl: '-10%' },
                width: { xs: 175, md: 200, lg: 200, xl: 200 },
              }}
            >
              <Box
                sx={{
                  position: 'relative', // Set position relative for absolute children
                }}
              >
                <Box
                  sx={{
                    position: 'absolute',
                    top: '1%', // Adjust the position from the top
                    right:
                      rightLocation[
                        Math.floor(Math.random() * rightLocation.length)
                      ], // Adjust the position from the right
                    width: '20vw', // Width of the circle
                    height: '20vw', // Height of the circle
                    backgroundColor:
                      whiteShades[
                        Math.floor(Math.random() * whiteShades.length)
                      ], // Color of the circle
                    boxShadow: '0 2px 10px rgba(0, 0, 0, 0.2)', // Optional shadow effect for the circle
                    zIndex: 0, // Ensure the circle is on top of the parent box
                    transform: 'rotate(45deg)',
                  }}
                />
              </Box>
              <CardContent
                sx={{
                  display: 'flex',
                  flexDirection: 'column',
                  alignItems: 'center', // Center items vertically
                  justifyContent: 'center', // Center items horizontally
                  zIndex: 1,
                }}
              >
                <Box
                  sx={{
                    flexGrow: 1,
                    margin: '1em',
                    textAlign: 'center', // Center text inside the Box
                  }}
                >
                  <img
                    src={papersContent[index].icon}
                    alt="icon"
                    style={{
                      display: 'block',
                      margin: '0 auto',
                      height: '40%',
                    }} // Center the icon horizontally
                  />
                  <Typography
                    variant="h4"
                    sx={{
                      color: papersContent[index].color,
                      fontWeight: 'bold',
                    }}
                  >
                    {papersContent[index].title}
                  </Typography>
                  <Typography variant="body2" sx={{ color: 'secondary' }}>
                    {papersContent[index].description}
                  </Typography>
                </Box>
              </CardContent>
            </Card>
          ))}
        </Box>
        <Box sx={{ display: 'flex', flexDirection: 'column' }}>
          {/* Second row: 3 cards evenly split */}
          {[2, 3].map(index => (
            <Card
              key={index}
              sx={{
                height: '100%',
                borderRadius: '16px',
                backgroundColor:
                  whiteShades[Math.floor(Math.random() * whiteShades.length)],
                display: 'flex',
                flexDirection: 'column',
                boxShadow: 3,
                overflow: 'hidden',
                position: 'relative', // Set position relative for absolute children
                mb: { xs: 4, md: 4, lg: 4, xl: 4 },
                mt: { xs: 4, md: 4, lg: 4, xl: 4 },
                mr: { xs: 0.5, md: 1, lg: 4, xl: 4 },
                ml: { xs: 0.5, md: 4, lg: 4, xl: 4 },
                top: { xs: '0%', md: '10%', lg: '10%', xl: '10%' },
                width: { xs: 175, md: 200, lg: 200, xl: 200 },
              }}
            >
              <Box
                sx={{
                  position: 'relative', // Set position relative for absolute children
                }}
              >
                <Box
                  sx={{
                    position: 'absolute',
                    top: '1%', // Adjust the position from the top
                    right:
                      rightLocation[
                        Math.floor(Math.random() * rightLocation.length)
                      ], // Adjust the position from the right
                    width: '20vw', // Width of the circle
                    height: '20vw', // Height of the circle
                    backgroundColor:
                      whiteShades[
                        Math.floor(Math.random() * whiteShades.length)
                      ], // Color of the circle
                    boxShadow: '0 2px 10px rgba(0, 0, 0, 0.2)', // Optional shadow effect for the circle
                    zIndex: 0, // Ensure the circle is on top of the parent box
                    transform: 'rotate(45deg)',
                  }}
                />
              </Box>
              <CardContent
                sx={{
                  display: 'flex',
                  flexDirection: 'column',
                  alignItems: 'center', // Center items vertically
                  justifyContent: 'center', // Center items horizontally
                  zIndex: 1,
                }}
              >
                <Box
                  sx={{
                    flexGrow: 1,
                    margin: '1em',
                    textAlign: 'center', // Center text inside the Box
                  }}
                >
                  <img
                    src={papersContent[index].icon}
                    alt="icon"
                    style={{
                      display: 'block',
                      margin: '0 auto',
                      height: '40%',
                    }} // Center the icon horizontally
                  />
                  <Typography
                    variant="h4"
                    sx={{
                      color: papersContent[index].color,
                      fontWeight: 'bold',
                    }}
                  >
                    {papersContent[index].title}
                  </Typography>
                  <Typography variant="body2" sx={{ color: 'secondary' }}>
                    {papersContent[index].description}
                  </Typography>
                </Box>
              </CardContent>
            </Card>
          ))}
        </Box>
      </Box>
    </Container>
  )
}
