import React, { useState } from 'react'
import { Box, Typography, CardContent, Card, useTheme } from '@mui/material'
import { keyframes } from '@mui/system'
import { useTranslation } from 'react-i18next'

const ServiceDetailsTwo = () => {
  const theme = useTheme()
  const { t, i18n } = useTranslation()
  const isArabic = i18n.language === 'ar'
  const rightLocation = theme.custom.rightLocation
  const whiteShades = theme.palette.custom.whiteShades
  const [checked, setChecked] = useState(false)
  const spin = keyframes`
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
`
  const characters = [
    {
      name: t('einvoice.service_1_name'),
      description: t('einvoice.service_1_para'),
    },
    {
      name: t('einvoice.service_2_name'),
      description: t('einvoice.service_2_para'),
    },
    {
      name: t('einvoice.service_3_name'),
      description: t('einvoice.service_3_para'),
    },
    {
      name: t('einvoice.service_4_name'),
      description: t('einvoice.service_4_para'),
    },
  ]
  return (
    <Box
      sx={{
        padding: 1,
        boxSizing: 'border-box',
        display: 'grid',
        gridTemplateColumns: { xs: '1fr', md: '1fr', lg: '2fr 2fr 2fr 2fr' },
        gap: 2,
      }}
    >
      {characters.map((character, index) => (
        <Card
          key={index}
          sx={{
            height: '100%',
            borderRadius: '16px',
            backgroundColor:
              whiteShades[Math.floor(Math.random() * whiteShades.length)],
            display: 'flex',
            flexDirection: 'column',
            boxShadow: 3,
            overflow: 'hidden',
            position: 'relative', // Set position relative for absolute children
          }}
        >
          <Box
            sx={{
              position: 'relative', // Set position relative for absolute children
            }}
          >
            <Box
              sx={{
                position: 'absolute',
                top: '1%', // Adjust the position from the top
                right:
                  rightLocation[
                    Math.floor(Math.random() * rightLocation.length)
                  ], // Adjust the position from the right
                width: '30vw', // Width of the circle
                height: '30vw', // Height of the circle
                backgroundColor:
                  whiteShades[Math.floor(Math.random() * whiteShades.length)], // Color of the circle
                boxShadow: '0 2px 10px rgba(0, 0, 0, 0.2)', // Optional shadow effect for the circle
                zIndex: 0, // Ensure the circle is on top of the parent box
                transform: 'rotate(45deg)',
                animation: `${spin} 10s infinite ease`,
              }}
            />
          </Box>
          <CardContent
            sx={{
              display: 'flex',
              flexDirection: 'row',
              alignItems: 'center', // Center items vertically
              justifyContent: 'center', // Center items horizontally
              zIndex: 1,
            }}
          >
            <Box
              sx={{
                flexGrow: 1,
                margin: '1em',
                textAlign: 'center', // Center text inside the Box
              }}
            >
              <Typography
                variant="h4"
                sx={{ color: 'secondary', fontWeight: 'bold' }}
              >
                {character.title}
              </Typography>
              <Typography variant="body2" sx={{ color: 'secondary' }}>
                {character.description}
              </Typography>
            </Box>
          </CardContent>
        </Card>
      ))}
    </Box>
  )
}

export default ServiceDetailsTwo
