import React, { useState } from 'react'
import {
  Box,
  Card,
  CardContent,
  Typography,
  IconButton,
  Modal,
  Button,
  useTheme,
  Container,
} from '@mui/material'
import { useTranslation } from 'react-i18next'
import { Info as InfoIcon } from '@mui/icons-material'
import business from '../../../assests/img/products/business@2x.png'
import payable from '../../../assests/img/products/payable@2x.png'
import powerPlant from '../../../assests/img/products/power-plant@2x.png'
import sales from '../../../assests/img/products/sales@2x.png'
import miniIcon from '../../../assests/img/products/miniIcon.png'
import { getTextDirection } from '../../../utils/getTextDirection'

export default function ERPModulesCards() {
  const theme = useTheme()
  const rightLocation = theme.custom.rightLocation
  const whiteShades = theme.palette.custom.whiteShades
  const greenColors = theme.palette.custom.lightGreenColors
  const { t, i18n } = useTranslation()
  const isArabic = i18n.language === 'ar'

  const direction = getTextDirection(i18n.language)
  const papersContent = [
    {
      title: t('products.accounting_title'),
      description: [
        t('products.accounting_para_1'),
        t('products.accounting_para_2'),
        t('products.accounting_para_3'),
        t('products.accounting_para_4'),
        t('products.accounting_para_5'),
        t('products.accounting_para_6'),
      ],
      icon: business,
    },
    {
      title: t('products.sales_purchase_inventory_title'),
      description: [
        t('products.sales_purchase_inventory_para_1'),
        t('products.sales_purchase_inventory_para_2'),
        t('products.sales_purchase_inventory_para_3'),
        t('products.sales_purchase_inventory_para_4'),
        t('products.sales_purchase_inventory_para_5'),
        t('products.sales_purchase_inventory_para_6'),
        t('products.sales_purchase_inventory_para_7'),
        t('products.sales_purchase_inventory_para_8'),
      ],
      icon: sales,
    },
    {
      title: t('products.manufacturing_title'),
      description: [
        t('products.manufacturing_para_1'),
        t('products.manufacturing_para_2'),
        t('products.manufacturing_para_3'),
        t('products.manufacturing_para_4'),
        t('products.manufacturing_para_5'),
      ],
      icon: powerPlant,
    },
    {
      title: t('products.personal_affairs_pos_title'),
      description: [
        t('products.personal_affairs_pos_para_1'),
        t('products.personal_affairs_pos_para_2'),
        t('products.personal_affairs_pos_para_3'),
        t('products.personal_affairs_pos_para_4'),
        t('products.personal_affairs_pos_para_5'),
        t('products.personal_affairs_pos_para_6'),
      ],
      icon: payable,
    },
  ]

  const [expandedIndex, setExpandedIndex] = useState(null)

  const handleToggle = index => {
    // Toggle the card's expanded state
    setExpandedIndex(expandedIndex === index ? null : index)
  }

  return (
    <Container sx={{ padding: 1, mb: '10%' }}>
      <Box
        sx={{
          padding: 1,
          boxSizing: 'border-box',
          display: 'grid',
          gridTemplateColumns: {
            xs: '1fr',
            md: '2fr 2fr',
            lg: '2fr 2fr 2fr 2fr',
          },
          gap: 2,
          maxWidth: '90vw',
          height: { sx: '', md: '', lg: '60vh' },
        }}
      >
        {papersContent.map((content, index) => (
          <Card
            key={index}
            onMouseEnter={() => handleToggle(index)}
            onMouseLeave={() => handleToggle(null)}
            sx={{
              borderRadius: '16px',
              height: 'fit-content', // Only expand the clicked card
              backgroundColor:
                expandedIndex === index
                  ? greenColors[Math.floor(Math.random() * greenColors.length)]
                  : whiteShades[Math.floor(Math.random() * whiteShades.length)],
              display: 'flex',
              flexDirection: 'column',
              boxShadow: 3,
              position: 'relative', // Keep the position relative for absolute children
              overflow: 'hidden',
              transition: 'transform 0.3s ease, background-color 0.3s ease',
              transform: expandedIndex === index ? 'scale(1.05)' : 'scale(1)',
              margin: 1, // Add margin for spacing
              zIndex: expandedIndex === index ? '5' : '1',
            }}
          >
            <Box sx={{ position: 'relative' }}>
              {/* Circle Box */}
              <Box
                sx={{
                  position: 'absolute',
                  top: '-10%', // Position it above the card (adjust as needed)
                  left: '50%', // Center it horizontally

                  borderRadius: '50%',
                  //     width: { md: 100vw lg: '20vw' }, // Width of the circle
                  //  height: { lg: '20vw' }, // Height of the circle
                  width: { xs: '100vw', md: '40vw', lg: '20vw' }, // Width of the circle
                  height: { xs: '100vw', md: '40vw', lg: '20vw' },
                  backgroundColor:
                    expandedIndex === index
                      ? whiteShades[
                          Math.floor(Math.random() * whiteShades.length)
                        ]
                      : greenColors[
                          Math.floor(Math.random() * greenColors.length)
                        ],
                  boxShadow: '0 2px 10px rgba(0, 0, 0, 0.2)', // Optional shadow effect for the circle
                  zIndex: 0, // Ensure the circle is behind the card content
                  transform: 'translate(-50%,-80%)', // Center and rotate
                }}
              />
            </Box>
            <CardContent
              sx={{
                display: 'flex',
                flexDirection: 'row',
                alignItems: 'center',
                justifyContent: 'center',
                padding: 2,
                zIndex: 1,
              }}
            >
              <Box sx={{ flexGrow: 1, margin: '1em', textAlign: 'center' }}>
                <img
                  src={content.icon}
                  alt="icon"
                  style={{
                    display: 'block',
                    margin: '0 auto',
                    width: '30%',
                    marginBottom: '1em',
                  }}
                />
                <Typography
                  variant="h5" // Adjust font size for better visibility
                  sx={{
                    color: 'secondary.main',
                    fontWeight: 'bold',
                    textAlign: 'center',
                  }}
                >
                  {content.title}
                </Typography>
              </Box>
            </CardContent>
            <Box
              sx={{ padding: 2 }}
              align={direction === 'rtl' ? 'right' : 'left'}
            >
              {content.description
                .slice(
                  0,
                  expandedIndex === index ? content.description.length : 3
                )
                .map((item, idx) => (
                  <Box
                    key={idx}
                    sx={{
                      display: 'flex',
                      flexDirection:
                        direction === 'rtl' ? 'row-reverse' : 'row',
                      alignItems: 'center',
                      marginBottom: 1,
                    }}
                    align={direction === 'rtl' ? 'right' : 'left'}
                  >
                    <img
                      src={miniIcon}
                      alt="bullet"
                      style={{
                        width: '12px', // Fixed width
                        height: '12px', // Fixed height to maintain aspect ratio
                        marginRight: direction === 'rtl' ? '0' : '8px',
                        marginLeft: direction === 'rtl' ? '8px' : '0',
                      }}
                    />
                    <Typography
                      variant="body2"
                      align={direction === 'rtl' ? 'right' : 'left'}
                    >
                      {item}
                    </Typography>
                  </Box>
                ))}
              {expandedIndex !== index && (
                <Button
                  align={direction === 'rtl' ? 'right' : 'left'}
                  sx={{
                    color: 'secondary.main',
                    fontWeight: 'bold',
                    marginTop: 1,
                  }}
                  onClick={() => handleToggle(index)}
                >
                  {t('home.read_more')}
                </Button>
              )}
            </Box>
          </Card>
        ))}
      </Box>
    </Container>
  )
}
