import React, { useState } from 'react'
import AppBar from '@mui/material/AppBar'
import Box from '@mui/material/Box'
import Toolbar from '@mui/material/Toolbar'
import Typography from '@mui/material/Typography'
import Button from '@mui/material/Button'
import HomeIcon from '@mui/icons-material/Home'
import PersonIcon from '@mui/icons-material/Person'
import SettingsIcon from '@mui/icons-material/Settings'
import { Divider } from '@mui/material'
import { Link, Navigate, useNavigate } from 'react-router-dom'
import user2 from '../../assests/img/user-square.svg'
import logoImg from '../../assests/img/logo.png'
import Drawer from '@mui/material/Drawer'
import IconButton from '@mui/material/IconButton'
import MenuIcon from '@mui/icons-material/Menu'
import LanguageToggle from './LanguageToggle'
import Home from '../pages/Home' // import your components
import About from '../pages/HomeComponents/About'
import Products from '../pages/HomeComponents/Products'
import Services from '../pages/HomeComponents/Services'
import EInvoicing from '../pages/Products'
import ContactUs from '../pages/ContactUs'
import ShoppingCartIcon from '@mui/icons-material/ShoppingCart'
import ConstructionIcon from '@mui/icons-material/Construction'
import { useTranslation } from 'react-i18next'
import DrawerList from './DrawerList'
import { useTheme } from '@mui/material/styles'
import EmailIcon from '@mui/icons-material/Email'
function ResponsiveAppBar() {
  const [drawerOpen, setDrawerOpen] = useState(false)
  const { t, i18n } = useTranslation()
  const isArabic = i18n.language === 'ar'
  const theme = useTheme()
  const navigate = useNavigate()
  const handleLinkClick = nav => {
    console.log(nav)
    window.scrollTo(0, 0) // Scroll to top
    navigate(nav) // Navigate to the specified route
  }
  const pages = [
    {
      title: t('navbar.home'),
      component: Home,
      icon: HomeIcon,
      nav: '/home',
    },
    {
      title: t('navbar.about_us'),
      component: About,
      icon: PersonIcon,
      nav: '/aboutus',
    },
    {
      title: t('navbar.products'),
      component: Products,
      icon: ShoppingCartIcon,
      nav: '/products',
    },
    {
      title: t('navbar.services'),
      component: Services,
      icon: ConstructionIcon,
      nav: '/services',
    },
    {
      title: t('navbar.e_invoicing'),
      component: EInvoicing,
      icon: SettingsIcon,
      nav: '/einvoicing',
    },
    {
      title: t('navbar.contact_us'),
      component: ContactUs,
      icon: EmailIcon,
      nav: '/contactus',
    },
  ]
  const toggleDrawer = open => event => {
    if (
      event.type === 'keydown' &&
      (event.key === 'Tab' || event.key === 'Shift')
    ) {
      return
    }
    setDrawerOpen(open)
  }
  // const drawerList = (
  //   <Box
  //     sx={{
  //       width: 250,
  //       backgroundColor: theme.palette.background.paper,
  //       padding: '16px',
  //       boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
  //       height: '100%',
  //       position: 'relative', // Ensure proper positioning for animations
  //     }}
  //     role="presentation"
  //     onClick={toggleDrawer(false)}
  //     onKeyDown={toggleDrawer(false)}
  //   >
  //     {pages.map((page, index) => (
  //       <Link
  //         to={`/${page.title.toLowerCase().replace(/\s+/g, '')}`}
  //         key={page.title}
  //         style={{
  //           display: 'flex',
  //           alignItems: 'center',
  //           padding: '12px 16px',
  //           color: theme.palette.Navbar.link,
  //           textDecoration: 'none',
  //           fontFamily: 'Montserrat, sans-serif',
  //           fontSize: '16px',
  //           fontWeight: 'normal',
  //           borderRadius: '4px',
  //           opacity: 1, // Set to 1 for the duration of the animation
  //           animation: `fallBounce 0.5s forwards ${index * 0.1}s`, // Animation for each link
  //         }}
  //       >
  //         {page.icon && (
  //           <Box sx={{ marginRight: '10px' }}>
  //             {React.createElement(page.icon)}
  //           </Box>
  //         )}
  //         {page.title}
  //       </Link>
  //     ))}

  //     <Divider sx={{ margin: '16px 0' }} />

  //     <Button
  //       href="######.html"
  //       sx={{
  //         width: '100%',
  //         fontFamily: 'Montserrat, sans-serif',
  //         color: theme.palette.Navbar.LoginButton.color,
  //         fontWeight: 300,
  //         fontSize: '14px',
  //         textAlign: 'center',
  //         lineHeight: '33px',
  //         borderRadius: '17px',
  //         border: '2px solid ' + theme.palette.Navbar.LoginButton.borderColor,
  //         cursor: 'pointer',
  //         display: 'flex',
  //         alignItems: 'center',
  //         padding: '10px',
  //         textDecoration: 'none',
  //         marginTop: '16px',
  //         opacity: 1, // Set to 1 for the duration of the animation
  //         animation: `fallBounce 0.5s forwards ${pages.length * 0.1 + 0.1}s`, // Delay for button
  //       }}
  //     >
  //       <img
  //         src={user2}
  //         alt="User Login"
  //         style={{
  //           marginRight: '6px',
  //           width: '20px',
  //           height: '20px',
  //         }}
  //       />
  //       <Typography variant="body2">{t('navbar.login')}</Typography>
  //     </Button>

  //     <Divider sx={{ margin: '16px 0' }} />

  //     <Box
  //       sx={{
  //         marginTop: '16px',
  //         display: 'flex',
  //         justifyContent: 'center',
  //         alignItems: 'center',
  //       }}
  //     >
  //       <LanguageToggle />
  //     </Box>
  //   </Box>
  // )

  return (
    <AppBar
      position="fixed"
      sx={{
        padding: 1,
      }}
    >
      <Toolbar disableGutters>
        {/* Move the menu icon to the left side of the screen */}
        <IconButton
          size="large"
          edge="start"
          aria-label="menu"
          sx={{
            ml: 2,
            mr: 2,
            color: theme.palette.Navbar.LoginButton.borderColor,
            display: { xs: 'flex', md: 'none' },
          }}
          onClick={toggleDrawer(true)}
        >
          <MenuIcon />
        </IconButton>
        <Box
          sx={{
            marginRight: 1,
            marginLeft: 2,
            paddingLeft: 10,
          }}
          onClick={() => handleLinkClick('/home')}
        >
          <img
            src={logoImg}
            alt="logo"
            sx={{
              display: { xs: 'none', md: 'flex' },
              marginRight: 1,
              marginLeft: 2,
            }}
          />
        </Box>
        <Box
          sx={{
            flexGrow: 1,
            display: { xs: 'none', md: 'flex' },
            paddingLeft: '5%',
          }}
        >
          {pages.map(page => (
            <Link
              to={`${page.nav}`}
              key={page.title}
              style={{
                marginRight: '14px',
                color: theme.palette.Navbar.link,
                textDecoration: 'none',
                position: 'relative', // Needed for absolute positioning of the underline
                fontFamily: 'Montserrat, sans-serif',
                fontSize: '12px',
                fontWeight: 'normal',
                overflow: 'hidden', // To prevent overflow of the underline
                display: 'inline-block', // Ensures the underline animates properly
              }}
              onClick={() => handleLinkClick(page.nav)}
              // onClick={Navigate(page.nav)}
              onMouseEnter={e => {
                e.currentTarget.style.color = theme.palette.primary.contrastText
                const underline = e.currentTarget.querySelector('.underline')
                if (underline) {
                  underline.style.width = '100%' // Expand underline on hover
                }
              }}
              onMouseLeave={e => {
                e.currentTarget.style.color = theme.palette.Navbar.link
                const underline = e.currentTarget.querySelector('.underline')
                if (underline) {
                  underline.style.width = '0' // Collapse underline on leave
                }
              }}
            >
              {page.title}
              <span
                className="underline"
                style={{
                  position: 'absolute',
                  bottom: '0',
                  left: '0',
                  height: '2px',
                  width: '0',
                  backgroundColor: theme.palette.Navbar.LoginButton.borderColor, // Color of the underline
                  transition: 'width 0.3s ease', // Smooth width transition
                }}
              />
            </Link>
          ))}
        </Box>
        {/* Drawer for mobile */}
        <Drawer anchor="left" open={drawerOpen} onClose={toggleDrawer(false)}>
          {DrawerList(pages, user2, t, toggleDrawer, theme, handleLinkClick)}
        </Drawer>

        <Box
          sx={{
            flexGrow: 0,
            marginLeft: 'auto',
            display: { xs: 'none', md: 'flex' },
            mr: 10,
          }}
        >
          <LanguageToggle />
          <Button
            href="######.html"
            sx={{
              opacity: 1,
              fontFamily: 'Montserrat, sans-serif',
              color: theme.palette.Navbar.LoginButton.color,
              fontWeight: 300,
              fontSize: '12px',
              textAlign: 'center',
              lineHeight: '33px',
              borderRadius: '17px',
              border:
                '2px solid ' + theme.palette.Navbar.LoginButton.borderColor,
              cursor: 'pointer',
              display: 'flex',
              alignItems: 'center',
              padding: '0 10px',
              textDecoration: 'none',
              '&:hover': {
                backgroundColor:
                  theme.palette.Navbar.LoginButton.hover.background,
              },
            }}
          >
            <img
              src={user2}
              alt="User Login"
              style={{
                marginRight: '6px',
                filter: 'none',
                width: '20px', // Optionally set width
                height: '20px', // Optionally set height
              }}
            />

            <Typography variant="body2">{t('navbar.login')}</Typography>
          </Button>
        </Box>
      </Toolbar>
    </AppBar>
  )
}

export default ResponsiveAppBar
