import React, { useState } from 'react'
import { Box, Typography, IconButton } from '@mui/material'
import { ArrowForward } from '@mui/icons-material'
import { useTheme, useMediaQuery } from '@mui/material'
import mobileImg from '../../../assests/img/background/computer.png'
import { blue } from '@mui/material/colors'
import { useTranslation } from 'react-i18next'
import { getTextDirection } from '../../../utils/getTextDirection'
import CRMimage from '../../../assests/img/products/CRMmobile.png'
import ERPimage from '../../../assests/img/products/ERPMobile.png'
import LYTimage from '../../../assests/img/products/Loyaltymobile.png'

export default function ImageCarousel() {
  const { t, i18n } = useTranslation()
  const isArabic = i18n.language === 'ar'

  const [currentBoxIndex, setCurrentBoxIndex] = useState(0)
  const [isAnimating, setIsAnimating] = useState(false)
  const theme = useTheme()
  const isMobile = useMediaQuery(theme.breakpoints.down('md'))
  const isSmallMobile = useMediaQuery(theme.breakpoints.down('sm'))
  console.log(theme)
  const colors = theme.palette.custom.cardColors
  const direction = getTextDirection(i18n.language)
  const boxesContent = [
    {
      title: t('home.erp_system_title'),
      description: isSmallMobile
        ? t('home.erp_system_para_mini')
        : t('home.erp_system_para'),
      imgSrc: ERPimage, // Add your image paths here
    },
    {
      title: t('home.crm_system_title'),
      description: isSmallMobile
        ? t('home.crm_system_para_mini')
        : t('home.crm_system_para'),
      imgSrc: CRMimage,
    },
    {
      title: t('home.hr_title'),
      description: isSmallMobile ? t('home.hr_para_mini') : t('home.hr_para'),
      imgSrc: LYTimage,
    },
  ]
  const handleNext = () => {
    if (!isAnimating) {
      setIsAnimating(true)
      setCurrentBoxIndex(prevIndex => (prevIndex + 1) % boxesContent.length)
      setTimeout(() => setIsAnimating(false), 700) // Duration of the animation
    }
  }
  // Dynamically assign z-index values based on the current index
  const getZIndex = index => {
    const len = colors.length
    return index === currentBoxIndex
      ? 3 // Top most
      : index === (currentBoxIndex + 1) % len
      ? 2 // Second in the order
      : 1 // Backmost
  }

  // Ensure all boxes are the same size, but add distinct transformations
  // const getTransform = index => {
  //   if (index === (currentBoxIndex + 1) % colors.length) {
  //     return 'scale(0.9) translateY(0%)' // Slightly smaller for the second one
  //   } else if (index === (currentBoxIndex + 2) % colors.length) {
  //     return 'scale(1) translateY(2%) rotate(2deg)' // Even smaller for the third
  //   }
  //   return 'scaleX(1) translateY(4%) rotate(-2deg)' // Normal size for the active one
  // }

  const getTransform = index => {
    if (index === (currentBoxIndex + 1) % colors.length) {
      return 'scale(1) translateY(-2.5%) translateX(2.5%)' // Slightly smaller for the second one
    } else if (index === (currentBoxIndex + 2) % colors.length) {
      return 'scale(1) translateY(-5%) translateX(5%)' // Even smaller for the third
    }
    return 'scaleX(1) translateY(0%) translateX(0%) ' // Normal size for the active one
  }

  return (
    <Box
      sx={{
        position: 'relative',
        width: '90%',
        // maxWidth: '1000px',
        mt: '10%',
        // overflow: 'hidden',
        borderRadius: '10px',
        //boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
        objectFit: 'cover',
        // height: '400px', // Adjust as needed
      }}
    >
      {/* Background Colors Layer */}
      {colors.map((color, index) => (
        <Box
          key={index}
          sx={{
            position: 'absolute',
            top: 0,
            left: 0,
            width: '100%',
            height: '100%',
            backgroundColor: color, // Use the color instead of an image
            zIndex: getZIndex(index),
            transform: getTransform(index), // Apply transform based on index
            transition:
              'transform 0.5s ease, z-index 0.5s ease, box-shadow 0.5s ease',
            borderRadius: '20px', // Set curved corners for background colors
            boxShadow: `0 4px 20px rgba(0, 0, 0, 0.2)`, // Add a subtle shadow effect
            '&:hover': {
              transform: `${getTransform(index)} scale(1.05)`, // Scale up on hover
              boxShadow: `0 8px 30px rgba(0, 0, 0, 0.3)`, // Increase shadow on hover
            },
            display: 'flex', // Enable flexbox for content alignment
            alignItems: direction === 'rtl' ? 'right' : 'left', // Center items vertically
            justifyContent: direction === 'rtl' ? 'right' : 'left', // Center items horizontally
            overflow: 'hidden',
          }}
        >
          <Box
            sx={{
              position: 'absolute',
              top: '-15%', // Adjust the position from the top
              left: '-15%', // Adjust the position from the right
              width: '30vw', // Width of the circle
              height: '30vw', // Height of the circle
              backgroundColor: color, // Color of the circle
              borderRadius: '50%', // Make it circular
              boxShadow: '0 2px 10px rgba(0, 0, 0, 0.2)', // Optional shadow effect for the circle
              zIndex: 10, // Ensure the circle is on top of the parent box
            }}
          />
          <Box
            sx={{
              position: 'absolute',
              bottom: '-5%', // Adjust the position from the top
              right: '-10%', // Adjust the position from the right
              width: '30vw', // Width of the circle
              height: '30vw', // Height of the circle
              backgroundColor: color, // Color of the circle
              boxShadow: '0 2px 10px rgba(0, 0, 0, 0.2)', // Optional shadow effect for the circle
              zIndex: 8, // Ensure the circle is on top of the parent box
            }}
          />
        </Box>
      ))}

      {/* Content Box */}
      <Box
        sx={{
          position: 'relative',
          display: 'flex',
          flexDirection: { xs: 'column', sm: 'column', md: 'row', lg: 'row' },
          // isMobile ? 'column' : 'row',
          alignItems: direction === 'rtl' ? 'right' : 'left',
          justifyContent: 'space-between',
          padding: '2rem',
          minHeight: '300px',
          zIndex: 4, // Ensure the content is above the background colors
          opacity: isAnimating ? 0 : 1,
          transition: 'opacity 0.5s ease',
          transform: isAnimating ? 'translateY(20px)' : 'translateY(0)',
          // mt: '10%',
        }}
      >
        {/* Text Section */}
        <Box
          sx={{
            flex: 1,
            color: 'primary.main',
            pr: { md: '0', lg: '2rem' },
            textAlign: direction === 'rtl' ? 'right' : 'left', // Adjust text alignment
            m: 3,
          }}
        >
          <Typography
            variant="h4"
            component="h1"
            gutterBottom
            sx={{
              transition: 'transform 0.5s ease, opacity 0.5s ease',
              transform: isAnimating ? 'scale(0.8)' : 'scale(1)',
              opacity: isAnimating ? 0 : 1,
            }}
          >
            {boxesContent[currentBoxIndex].title}
          </Typography>
          <Typography
            variant="body1"
            gutterBottom
            sx={{
              transition: 'transform 0.5s ease, opacity 0.5s ease',
              transform: isAnimating ? 'scale(0.8)' : 'scale(1)',
              opacity: isAnimating ? 0 : 1,
            }}
          >
            {boxesContent[currentBoxIndex].description}
          </Typography>
        </Box>

        {/* Image Section */}
        <Box
          sx={{
            flex: { md: '1', lg: '0.5' },
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          <img
            src={boxesContent[currentBoxIndex].imgSrc}
            alt={boxesContent[currentBoxIndex].title}
            style={{
              width: isMobile ? '100%' : '90%',
              transition: 'opacity 0.5s ease',
              opacity: isAnimating ? 0 : 1,
              borderRadius: '10px', // Rounded corners for the image
            }}
          />
        </Box>
      </Box>

      {/* Next Button */}
      <IconButton
        onClick={handleNext}
        sx={{
          position: 'absolute',
          right: '10px',
          top: '50%',
          transform: 'translateY(-50%)',
          backgroundColor: 'primary.main',
          color: colors[currentBoxIndex],
          zIndex: 5, // Ensure the button is clickable
          borderRadius: '50%', // Make the button circular
          width: '48px',
          height: '48px',
          boxShadow: '0 4px 8px rgba(0, 0, 0, 0.2)', // Add shadow for depth
          transition: 'background-color 0.3s ease, transform 0.3s ease',
          '&:hover': {
            backgroundColor: 'primary.dark',
            transform: 'translateY(-50%) scale(1.1)', // Slightly increase the size on hover
          },
          '&:focus': {
            outline: 'none', // Remove default focus outline
            boxShadow: '0 0 0 4px ' + colors[currentBoxIndex], // Add custom focus outline
          },
        }}
      >
        <ArrowForward />
      </IconButton>
    </Box>
  )
}
