import React, { useState } from 'react'
import {
  Box,
  Card,
  CardContent,
  Typography,
  IconButton,
  Modal,
  Button,
  Container,
  useTheme,
} from '@mui/material'
import { useNavigate } from 'react-router-dom'
import { Info as InfoIcon } from '@mui/icons-material'
import analysisIcon from '../../../assests/img/icons/analysisIcon.svg'
import bracketsIcon from '../../../assests/img/icons/bracketsIcon.svg'
import businessIcon from '../../../assests/img/icons/businessIcon.svg'
import cloudIcon from '../../../assests/img/icons/cloudIcon.svg'
import laptopIcon from '../../../assests/img/icons/laptopIcon.svg'
import listIcon from '../../../assests/img/icons/listIcon.svg'
import phoneIcon from '../../../assests/img/icons/phoneIcon.svg'
import { useTranslation } from 'react-i18next'
import { getTextDirection } from '../../../utils/getTextDirection'

// Predefined colors (shades of green, blue, and yellow)

export default function ServicesShuffle() {
  const { t, i18n } = useTranslation()

  const direction = getTextDirection(i18n.language)
  const theme = useTheme()
  const rightLocation = theme.custom.rightLocation
  const yellowColors = theme.palette.custom.yellowColors
  const navigate = useNavigate()
  const greenColors = theme.palette.custom.greenColors
  const [openModalIndex, setOpenModalIndex] = useState(null)
  const papersContent = [
    {
      title: t('home.service_erp_title'),
      description: t('home.service_erp_para'),
    },
    {
      title: t('home.service_invoice_title'),
      description: t('home.service_invoice_para'),
    },
    {
      title: t('home.service_dev_title'),
      description: t('home.service_dev_para'),
    },
    {
      title: t('home.service_Mana_title'),
      description: t('home.service_Mana_para'),
    },
    {
      title: t('home.service_cloud_title'),
      description: t('home.service_cloud_para'),
    },

    {
      title: t('home.service_ecomm_title'),
      description: t('home.service_ecomm_para'),
    },
    {
      title: t('home.service_net_title'),
      description: t('home.service_net_para'),
    },
  ]

  const links = ['dev', 'mana', 'cloud']
  return (
    <Container sx={{ padding: 1 }}>
      <Box
        sx={{
          padding: 1,
          boxSizing: 'border-box',
          display: 'grid',
          gridTemplateColumns: { xs: '1fr', md: '1fr', lg: '2fr 3fr' },
          gap: 2,
        }}
      >
        {/* First row: 40% and 60% */}

        <Card
          sx={{
            height: '100%',
            borderRadius: '16px',
            backgroundColor:
              greenColors[Math.floor(Math.random() * greenColors.length)],
            display: 'flex',
            flexDirection: 'column',
            boxShadow: 3,
            overflow: 'hidden',
            position: 'relative', // Set position relative for absolute children
          }}
        >
          <Box
            sx={{
              position: 'relative', // Set position relative for absolute children
            }}
          >
            <Box
              sx={{
                position: 'absolute',
                top: '1%', // Adjust the position from the top
                right:
                  rightLocation[
                    Math.floor(Math.random() * rightLocation.length)
                  ], // Adjust the position from the right
                width: '30vw', // Width of the circle
                height: '30vw', // Height of the circle
                backgroundColor:
                  greenColors[Math.floor(Math.random() * greenColors.length)], // Color of the circle
                boxShadow: '0 2px 10px rgba(0, 0, 0, 0.2)', // Optional shadow effect for the circle
                zIndex: 0, // Ensure the circle is on top of the parent box
                transform: 'rotate(45deg)',
              }}
            />
          </Box>
          <CardContent
            sx={{ display: 'flex', flexDirection: 'row', zIndex: 1 }}
          >
            <Box sx={{ flexGrow: 1, margin: '1em' }}>
              <Typography
                variant="h4"
                align={direction === 'rtl' ? 'right' : 'left'}
                sx={{ color: 'primary.main', fontWeight: 'bold' }}
              >
                {papersContent[0].title}
              </Typography>
              <Typography variant="body2" sx={{ color: 'primary.main' }}>
                {papersContent[0].description}
              </Typography>
              <Button
                href={`/services#erpc`}
                sx={{
                  color: 'primary.main',
                  textTransform: 'none',
                  mt: 4,
                  mb: 0,
                }}
              >
                {t('home.learn_more')}
              </Button>
            </Box>
            <Box sx={{ alignContent: 'center' }}>
              <img src={phoneIcon} alt="phoneIcon" />
            </Box>
          </CardContent>
        </Card>
        <Card
          sx={{
            height: '100%',
            borderRadius: '16px',
            backgroundColor:
              greenColors[Math.floor(Math.random() * greenColors.length)],
            display: 'flex',
            flexDirection: 'column',
            boxShadow: 3,
            overflow: 'hidden',
            position: 'relative',
          }}
        >
          <Box
            sx={{
              position: 'relative', // Set position relative for absolute children
            }}
          >
            <Box
              sx={{
                position: 'absolute',
                top: '1%', // Adjust the position from the top
                left: '1%', // Adjust the position from the right
                width: '20vw', // Width of the circle
                height: '30vw', // Height of the circle
                backgroundColor:
                  greenColors[Math.floor(Math.random() * greenColors.length)], // Color of the circle
                boxShadow: '0 2px 10px rgba(0, 0, 0, 0.2)', // Optional shadow effect for the circle
                zIndex: 0, // Ensure the circle is on top of the parent box
                transform: 'rotate(45deg)',
              }}
            />
            <Box
              sx={{
                position: 'absolute',
                top: '1%', // Adjust the position from the top
                right:
                  rightLocation[
                    Math.floor(Math.random() * rightLocation.length)
                  ], // Adjust the position from the right
                width: '20vw', // Width of the circle
                height: '30vw', // Height of the circle
                backgroundColor:
                  greenColors[Math.floor(Math.random() * greenColors.length)], // Color of the circle
                boxShadow: '0 2px 10px rgba(0, 0, 0, 0.2)', // Optional shadow effect for the circle
                zIndex: 0, // Ensure the circle is on top of the parent box
                transform: 'rotate(-45deg)',
              }}
            />
          </Box>
          <CardContent
            sx={{ display: 'flex', flexDirection: 'row', zIndex: 1 }}
          >
            <Box sx={{ flexGrow: 1, margin: '1em' }}>
              <Typography
                variant="h4"
                align={direction === 'rtl' ? 'right' : 'left'}
                sx={{ color: 'primary.main', fontWeight: 'bold' }}
              >
                {papersContent[1].title}
              </Typography>
              <Typography variant="body2" sx={{ color: 'primary.main' }}>
                {papersContent[1].description}
              </Typography>
              <Button
                href={`/einvoicing`}
                sx={{
                  color: 'primary.main',
                  textTransform: 'none',
                  mt: 4,
                  mb: 0,
                }}
              >
                {t('home.learn_more')}
              </Button>
            </Box>
            <Box sx={{ alignContent: 'center' }}>
              <img src={analysisIcon} alt="analysisIcon" />
            </Box>
          </CardContent>
        </Card>
      </Box>
      <Box
        sx={{
          padding: 1,
          boxSizing: 'border-box',
          display: 'grid',
          gridTemplateColumns: { xs: '1fr', md: '1fr', lg: '2fr 2fr 2fr' },
          gap: 2,
        }}
      >
        {/* Second row: 3 cards evenly split */}
        {[0, 1, 2].map(index => (
          <Card
            key={index}
            sx={{
              height: '100%',
              borderRadius: '16px',
              backgroundColor:
                yellowColors[Math.floor(Math.random() * yellowColors.length)],
              display: 'flex',
              flexDirection: 'column',
              boxShadow: 3,
              overflow: 'hidden',
              position: 'relative', // Set position relative for absolute children
            }}
          >
            <Box
              sx={{
                position: 'relative', // Set position relative for absolute children
              }}
            >
              <Box
                sx={{
                  position: 'absolute',
                  top: '1%', // Adjust the position from the top
                  right:
                    rightLocation[
                      Math.floor(Math.random() * rightLocation.length)
                    ], // Adjust the position from the right
                  width: '30vw', // Width of the circle
                  height: '30vw', // Height of the circle
                  backgroundColor:
                    yellowColors[
                      Math.floor(Math.random() * yellowColors.length)
                    ], // Color of the circle
                  boxShadow: '0 2px 10px rgba(0, 0, 0, 0.2)', // Optional shadow effect for the circle
                  zIndex: 0, // Ensure the circle is on top of the parent box
                  transform: 'rotate(45deg)',
                }}
              />
            </Box>
            <CardContent
              sx={{ display: 'flex', flexDirection: 'row', zIndex: 1 }}
            >
              <Box sx={{ flexGrow: 1, margin: '1em' }}>
                <Typography
                  variant="h4"
                  align={direction === 'rtl' ? 'right' : 'left'}
                  sx={{ color: 'primary.main', fontWeight: 'bold' }}
                >
                  {papersContent[index + 2].title}
                </Typography>
                <Typography variant="body2" sx={{ color: 'primary.main' }}>
                  {papersContent[index + 2].description}
                </Typography>
                <Button
                  href={`/services#${links[index]}`}
                  sx={{
                    color: 'primary.main',
                    textTransform: 'none',
                    mt: 4,
                    mb: 0,
                  }}
                >
                  {t('home.learn_more')}
                </Button>
              </Box>
              <Box sx={{ alignContent: 'center' }}>
                <img
                  src={[bracketsIcon, listIcon, cloudIcon][index]}
                  alt="icon"
                />
              </Box>
            </CardContent>
          </Card>
        ))}
      </Box>
      <Box
        sx={{
          padding: 1,
          boxSizing: 'border-box',
          display: 'grid',
          gridTemplateColumns: { xs: '1fr', md: '1fr', lg: '2fr 3fr' },
          gap: 2,
        }}
      >
        {/* Third row: 60% and 40% */}
        <Card
          sx={{
            height: '100%',
            borderRadius: '16px',
            backgroundColor:
              greenColors[Math.floor(Math.random() * greenColors.length)],
            display: 'flex',
            flexDirection: 'column',
            boxShadow: 3,
            overflow: 'hidden',
            position: 'relative', // Set position relative for absolute children
          }}
        >
          <Box
            sx={{
              position: 'relative', // Set position relative for absolute children
            }}
          >
            <Box
              sx={{
                position: 'absolute',
                top: '1%', // Adjust the position from the top
                right:
                  rightLocation[
                    Math.floor(Math.random() * rightLocation.length)
                  ], // Adjust the position from the right
                width: '30vw', // Width of the circle
                height: '30vw', // Height of the circle
                backgroundColor:
                  greenColors[Math.floor(Math.random() * greenColors.length)], // Color of the circle
                boxShadow: '0 2px 10px rgba(0, 0, 0, 0.2)', // Optional shadow effect for the circle
                zIndex: 0, // Ensure the circle is on top of the parent box
                transform: 'rotate(45deg)',
              }}
            />
          </Box>
          <CardContent
            sx={{ display: 'flex', flexDirection: 'row', zIndex: 1 }}
          >
            <Box sx={{ flexGrow: 1, margin: '1em' }}>
              <Typography
                variant="h4"
                align={direction === 'rtl' ? 'right' : 'left'}
                sx={{ color: 'primary.main', fontWeight: 'bold' }}
              >
                {papersContent[5].title}
              </Typography>
              <Typography variant="body2" sx={{ color: 'primary.main' }}>
                {papersContent[5].description}
              </Typography>
              <Button
                href={`/services#ecomm`}
                sx={{
                  color: 'primary.main',
                  textTransform: 'none',
                  mt: 4,
                  mb: 0,
                }}
              >
                {t('home.learn_more')}
              </Button>
            </Box>
            <Box sx={{ alignContent: 'center' }}>
              <img src={businessIcon} alt="businessIcon" />
            </Box>
          </CardContent>
        </Card>

        <Card
          sx={{
            height: '100%',
            borderRadius: '16px',
            backgroundColor:
              greenColors[Math.floor(Math.random() * greenColors.length)],
            display: 'flex',
            flexDirection: 'column',
            boxShadow: 3,
            overflow: 'hidden',
            position: 'relative', // Set position relative for absolute children
          }}
        >
          <Box
            sx={{
              position: 'relative', // Set position relative for absolute children
            }}
          >
            <Box
              sx={{
                position: 'absolute',
                top: '1%', // Adjust the position from the top
                left: '1%', // Adjust the position from the right
                width: '20vw', // Width of the circle
                height: '30vw', // Height of the circle
                backgroundColor:
                  greenColors[Math.floor(Math.random() * greenColors.length)], // Color of the circle
                boxShadow: '0 2px 10px rgba(0, 0, 0, 0.2)', // Optional shadow effect for the circle
                zIndex: 0, // Ensure the circle is on top of the parent box
                transform: 'rotate(45deg)',
              }}
            />
            <Box
              sx={{
                position: 'absolute',
                top: '1%', // Adjust the position from the top
                right:
                  rightLocation[
                    Math.floor(Math.random() * rightLocation.length)
                  ], // Adjust the position from the right
                width: '20vw', // Width of the circle
                height: '30vw', // Height of the circle
                backgroundColor:
                  greenColors[Math.floor(Math.random() * greenColors.length)], // Color of the circle
                boxShadow: '0 2px 10px rgba(0, 0, 0, 0.2)', // Optional shadow effect for the circle
                zIndex: 0, // Ensure the circle is on top of the parent box
                transform: 'rotate(-45deg)',
              }}
            />
          </Box>
          <CardContent
            sx={{ display: 'flex', flexDirection: 'row', zIndex: 1 }}
          >
            <Box sx={{ flexGrow: 1, margin: '1em' }}>
              <Typography
                variant="h4"
                align={direction === 'rtl' ? 'right' : 'left'}
                sx={{ color: 'primary.main', fontWeight: 'bold' }}
              >
                {papersContent[6].title}
              </Typography>
              <Typography variant="body2" sx={{ color: 'primary.main' }}>
                {papersContent[6].description}
              </Typography>
              <Button
                href={`/services#cloud`}
                sx={{
                  color: 'primary.main',
                  textTransform: 'none',
                  mt: 4,
                  mb: 0,
                }}
              >
                {t('home.learn_more')}
              </Button>
            </Box>
            <Box sx={{ alignContent: 'center' }}>
              <img src={laptopIcon} alt="laptopIcon" />
            </Box>
          </CardContent>
        </Card>
      </Box>
    </Container>
  )
}
