import { Box, Button, Container, Typography } from '@mui/material'
import computerImg from '../../../assests/img/background/computerWithBackground.png'
import mobileImg from '../../../assests/img/background/computer.png' // Image for mobile
import { useTheme, useMediaQuery } from '@mui/material'
import { useTranslation } from 'react-i18next'
import BackgroundCircle from '../../common/BackgroundCircle'

export default function Main() {
  const theme = useTheme()
  const isLarge = useMediaQuery(theme.breakpoints.down('lg')) // Adjust the breakpoint
  const { t, i18n } = useTranslation()
  const isArabic = i18n.language === 'ar'
  return (
    <Box
      sx={{
        mt: '72px',
        display: 'flex',
        flexDirection: { xs: 'column', sm: 'column', md: 'row', lg: 'row' }, // Stack on mobile
        alignItems: 'center',
        justifyContent: 'center',
        backgroundImage: {
          xs: 'none',
          md: 'none',
          lg: `url(${computerImg})`,
        }, //!isLarge ? `url(${computerImg})` : 'none', // Background image for larger screens
        backgroundPosition: 'right',
        backgroundRepeat: 'no-repeat',
        backgroundSize: 'contain',
        height: { xs: 'none', md: 'none', lg: '100vh' }, // !isMobile ? '100vh' : 'none',
        zIndex: 1,
      }}
    >
      <BackgroundCircle Nb={0} position={{ top: '-60vh', left: '5vw' }} />

      {/* Left Column: Text */}
      <Box
        sx={{
          flex: 1,
          padding: { xs: '1rem', md: '1rem', lg: '3rem' }, // isMobile ? '1rem' : '3rem',
          maxWidth: { xs: '100%', md: '100%', lg: '45%', xl: '50%' },
          // isMobile ? '100%' : isExtraLarge ? '100%' : '40%',
          justifyContent: 'center',
          alignItems: 'center',
          //justifyContent: 'space-between',
          backgroundSize: '50%',
          ml: { md: '0', lg: 5 },
        }}
      >
        {' '}
        <Box align="center">
          <Typography
            variant="h1"
            component="h1"
            sx={{
              mb: 0,
              justifyContent: 'center',
              align: 'center',
              fontSize: { sm: '2.5rem', md: '3rem', lg: '4rem' },
            }}
            gutterBottom
          >
            {t('home.matrix')}
          </Typography>
          <Typography
            variant="h2"
            display="inline"
            sx={{
              whiteSpace: 'initial',
              mb: 1,
              justifyContent: 'center',
              align: 'center',
            }}
          >
            {t('home.business')}
          </Typography>
          <Typography
            variant="h6"
            color="secondary"
            sx={{
              whiteSpace: 'initial',
              mt: 2,
              justifyContent: 'center',
              align: 'center',
            }}
            gutterBottom
          >
            {t('home.qoute')}
          </Typography>
          <Typography
            variant="body1"
            sx={{ justifyContent: 'center', align: 'center' }}
          >
            {t('home.paragraph')}
            <Typography
              component="span"
              variant="body1"
              sx={{ justifyContent: 'center', align: 'center' }}
            >
              {' .'}
            </Typography>
          </Typography>

          <Button
            variant="contained"
            color="secondary"
            size="large"
            sx={{
              mt: 2,
              borderRadius: 8,
              alignContent: 'center',
              alignItems: 'center',
            }}
          >
            <Typography
              color="primary"
              sx={{
                mt: 0.5,
                mb: 0.5,
              }}
              gutterBottom
            >
              {t('home.service_Button')}
            </Typography>
          </Button>
        </Box>
      </Box>
      {/* Right Column: Image */}
      {!isLarge ? (
        <Box sx={{ flex: 1 }}>
          {/* Image for larger screens is part of the background */}
        </Box>
      ) : (
        <Box
          sx={{
            mt: 4,
            mb: 4,
            minHeight: '200px', // Ensures space is reserved for the image
            position: 'relative', // Enables positioning adjustments
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          <img
            src={mobileImg} // Image for mobile
            alt="Mobile App Showcase"
            style={{
              width: '100%',
              maxWidth: '100%',
              justifyContent: 'center',
              alignItems: 'center',
              marginBottom: 4,
              //height: '100%',
              objectFit: 'cover',
            }} // Responsive styling
          />
        </Box>
      )}
    </Box>
  )
}
