import React, { useEffect, useRef, useState } from 'react'
import {
  Stepper,
  Step,
  StepLabel,
  StepButton,
  Paper,
  Button,
  Typography,
  Box,
  StepIcon,
  IconButton,
  useMediaQuery,
} from '@mui/material'
import Cards from './Services/Cards'
import HorizontalRuleIcon from '@mui/icons-material/HorizontalRule'
import theme from '../../theme'
import { useTranslation } from 'react-i18next'
import cloud from '../../assests/img/services/cloud.png'
import customdev from '../../assests/img/services/customdev.png'
import datamana from '../../assests/img/services/datamana.png'
import commerce from '../../assests/img/services/e-commerce.png'
import erp from '../../assests/img/services/erp.png'
import network from '../../assests/img/services/network.png'
import ImgRight from '../common/ImgRight'
import ImgLeft from '../common/ImgLeft'
import { useTheme } from '@mui/material/styles'
import ArrowForwardIcon from '@mui/icons-material/ArrowForward'
import ERPConsulation from './Services/ERPConsulation'
import MenuIcon from '@mui/icons-material/Menu'
import CloseIcon from '@mui/icons-material/Close'
import ERPImprovments from './Services/ERPImprovments'
import CustomDev from './Services/CustomDev'
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos'
import { keyframes } from '@mui/system'

export default function Services() {
  const [activeStep, setActiveStep] = useState(0)
  const sectionRefs = [
    useRef(null),
    useRef(null),
    useRef(null),
    useRef(null),
    useRef(null),
    useRef(null),
  ]
  const steps = ['erpc', 'dev', 'mana', 'net', 'ecomm', 'cloud']
  const [open, setOpen] = useState(false)

  const toggleStepper = () => {
    setOpen(!open)
  }
  const bounce = keyframes` 0%, 100% { transform: translateY(-50%) scale(1); } 50% { transform: translateY(-50%) scale(1.2); } `
  const wiggle = keyframes` 0% { transform: translateY(-50%) rotate(0deg); } 25% { transform: translateY(-50%) rotate(-5deg); } 50% { transform: translateY(-50%) rotate(0deg); } 75% { transform: translateY(-50%) rotate(5deg); } 100% { transform: translateY(-50%) rotate(0deg); } `
  const { t, i18n } = useTranslation()
  const isArabic = i18n.language === 'ar'
  const theme = useTheme()
  const isLarge = useMediaQuery(theme.breakpoints.down('lg')) // Adjust the breakpoint
  useEffect(() => {
    const observers = sectionRefs.map((ref, index) => {
      const observer = new IntersectionObserver(
        ([entry]) => {
          if (entry.isIntersecting) {
            setActiveStep(index)
          }
        },
        { threshold: 0.5 }
      )

      if (ref.current) {
        observer.observe(ref.current)
      }

      return observer
    })

    return () => {
      observers.forEach(observer => observer.disconnect())
    }
  }, [])

  const handleStepClick = step => {
    setActiveStep(step)
    sectionRefs[step].current?.scrollIntoView({ behavior: 'smooth' })
  }

  useEffect(() => {
    const hash = window.location.hash
    if (hash) {
      const index = steps.findIndex(step => `#${step.toLowerCase()}` === hash)
      if (index !== -1) {
        setActiveStep(index)
        sectionRefs[index].current.scrollIntoView({ behavior: 'smooth' })
      }
    }
  }, []) // Run only on mount
  const handleSectionClick = step => {
    setActiveStep(step)
  }
  const data = [
    {
      title: t('services.erp_title'),
      text: t('services.erp_para'),
      img: erp,
    },
    {
      title: t('services.dev_title'),
      text: t('services.dev_para'),
      img: customdev,
    },
    {
      title: t('services.Mana_title'),
      text: t('services.Mana_para'),
      img: datamana,
    },
    {
      title: t('services.net_title'),
      text: t('services.net_para'),
      img: network,
    },
    {
      title: t('services.ecomm_title'),
      text: t('services.ecomm_para'),
      img: commerce,
    },
    {
      title: t('services.cloud_title'),
      text: t('services.cloud_para'),
      img: cloud,
    },
  ]
  return (
    <Box mt="72px">
      {/* Move Stepper to the right */}
      <Box style={{ flexGrow: 1 }}>
        <section id="erpc" ref={sectionRefs[0]}>
          {/* <ERPSoftwareSolution /> */}

          <ImgRight
            text={data[0].text}
            imgsrc={data[0].img}
            title={data[0].title}
          />
          <ERPConsulation />
          <ERPImprovments />
        </section>

        <section id="dev" ref={sectionRefs[1]}>
          <ImgLeft
            text={data[1].text}
            imgsrc={data[1].img}
            title={data[1].title}
          />
          <CustomDev />
        </section>

        <section id="mana" ref={sectionRefs[2]}>
          <ImgRight
            text={data[2].text}
            imgsrc={data[2].img}
            title={data[2].title}
          />
        </section>
        <section id="net" ref={sectionRefs[3]}>
          <ImgLeft
            text={data[3].text}
            imgsrc={data[3].img}
            title={data[3].title}
          />
        </section>
        <section id="ecomm" ref={sectionRefs[4]}>
          <ImgRight
            text={data[4].text}
            imgsrc={data[4].img}
            title={data[4].title}
          />
        </section>
        <section id="cloud" ref={sectionRefs[5]}>
          <ImgLeft
            text={data[5].text}
            imgsrc={data[5].img}
            title={data[5].title}
          />
        </section>
      </Box>
      <IconButton
        onClick={toggleStepper}
        sx={{
          position: 'fixed',
          top: '50%',
          right: open ? '260px' : '10px',
          transform: 'translateY(-50%)',
          zIndex: 1100,
          // backgroundColor: theme.palette.primary.main,
          // color: theme.palette.secondary.contrastText,
          borderRadius: '50%',
          width: '48px',
          height: '48px',
          boxShadow: '0 4px 8px rgba(0, 0, 0, 0.2)',
          transition: 'right 0.3s ease',
        }}
      >
        {open ? <CloseIcon /> : <ArrowBackIosIcon />}
      </IconButton>
      {/* Paper component on the right side */}
      {open && (
        <Paper
          sx={{
            position: 'fixed',
            top: '50%',
            right: 0,
            transform: 'translateY(-50%)',
            zIndex: 1000,
            p: 1,
            width: 'auto',
            backgroundColor: theme.palette.background.paper,
            boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
            borderRadius: '8px 0 0 8px',
          }}
          elevation={4}
        >
          <Stepper activeStep={activeStep} orientation="vertical" nonLinear>
            {steps.map((label, index) => (
              <Step key={label} completed={false} sx={{ padding: '4px 0' }}>
                <StepButton
                  onClick={() => handleStepClick(index)}
                  icon={
                    <HorizontalRuleIcon
                      style={{
                        transform: 'rotate(90deg)',
                        color: theme.palette.text.third,
                      }}
                    />
                  }
                >
                  <StepIcon />
                  <StepLabel
                    icon={<HorizontalRuleIcon />}
                    sx={{
                      '& .MuiStepLabel-label': {
                        color: theme.palette.text.primary,
                        fontSize: '0.75rem',
                      },
                      '& .MuiStepLabel-iconContainer': { pr: 0.5 },
                      display: 'flex',
                      alignItems: 'center',
                    }}
                  >
                    <ArrowForwardIcon
                      fontSize="small"
                      sx={{ mr: 1, color: theme.palette.text.secondary }}
                    />
                    {label}
                  </StepLabel>
                </StepButton>
              </Step>
            ))}
          </Stepper>
        </Paper>
      )}
    </Box>
  )
}
