// src/theme.js
import React, { useMemo } from 'react'
import { createTheme, ThemeProvider } from '@mui/material/styles'
import useDirection from './hooks/useDirection'

const ThemeProviderComponent = ({ children }) => {
  const direction = useDirection()

  const theme = useMemo(
    () =>
      createTheme({
        direction,
        palette: {
          primary: { main: '#FFFFFF', contrastText: '#000000' },
          secondary: { main: '#64c290', contrastText: '#FFFFFF' },
          background: { default: '#f5f5f5', paper: '#FFFFFF' },
          text: { primary: '#000000', secondary: '#FFFFFF', third: '#808080' },
          error: { main: '#D32F2F' },
          warning: { main: '#FFA000' },
          info: { main: '#1976D2' },
          success: { main: '#388E3C' },
          Navbar: {
            link: '#929292',
            hover: '#64b6a1',
            active: '#4e9b83',
            LoginButton: {
              color: '#202020',
              borderColor: '#559F82',
              hover: { color: '#FFFFFF', background: '#64c290' },
            },
          },
          NextIcon: { background: '#ffffff33', hover: '#ffffff66' },
          button: {
            primary: { main: '#64c290', hover: '#5bb78c' },
            secondary: { main: '#FFFFFF', hover: '#f0f0f0' },
          },
          card: {
            background: '#FFFFFF',
            shadow: '0px 4px 8px rgba(0, 0, 0, 0.1)',
          },
          custom: {
            yellowColor: '#efdf00',
            whiteShades: [
              '#f0f0f0',
              '#f7f7f7',
              '#fafafa',
              '#ffffff',
              '#e0e0e0',
              '#ffffff',
              '#ffffff',
            ],
            yellowColors: ['#cfc75b', '#a29b2e'],
            lightYellowColors: ['#e0d000', '#dfd000', '#f9e800', '#fae800'],
            greenColors: [
              '#67c18c',
              '#559f82',
              '#2a7457',
              '#83c49d',
              '#8ec9a6',
            ],
            lightGreenColors: [
              '#dbece2',
              '#b0ddc2',
              '#d4ecde',
              '#d4ecd8',
              '#c1e2cf',
              '#e4f2ea',
              '#c2e5d0',
            ],
            cardColors: ['#cfc75b', '#67c18c', '#559f82'],
          },
        },
        breakpoints: {
          values: { xs: 0, sm: 600, md: 900, lg: 1200, xl: 1536 },
        },
        typography: {
          h1: {
            fontSize: '2.5rem', // Default font size
            '@media (min-width:600px)': {
              fontSize: '2.5rem',
            },
            '@media (min-width:900px)': {
              fontSize: '3rem',
            },
            '@media (min-width:1200px)': {
              fontSize: '4rem',
            },
            '@media (min-width:1536px)': {
              fontSize: '4.5rem',
            },
            fontWeight: 'bold',
            color: '#000000',
            direction: direction,

            fontFamily: 'Montserrat-Arabic',
          },
          h2: {
            fontSize: '2rem',
            '@media (min-width:600px)': {
              fontSize: '2rem',
            },
            '@media (min-width:900px)': {
              fontSize: '2.5rem',
            },
            '@media (min-width:1200px)': {
              fontSize: '3rem',
            },
            '@media (min-width:1536px)': {
              fontSize: '4rem',
            },
            fontWeight: 'bold',
            color: '#333333',
            direction: direction,

            fontFamily: 'Montserrat-Arabic',
          },
          h3: {
            fontSize: '1.2rem',
            color: '#333333',
            direction: direction,
            fontFamily: 'Montserrat-Arabic',
          },
          body1: {
            fontSize: '1rem',
            '@media (min-width:600px)': {
              fontSize: '1rem',
            },
            '@media (min-width:900px)': {
              fontSize: '1.1rem',
            },
            '@media (min-width:1200px)': {
              fontSize: '1.3rem',
            },
            color: '#000000',
            textAlign: direction === 'rtl' ? 'right' : 'left',
            align: direction === 'rtl' ? 'right' : 'left',
            direction: direction,

            fontFamily: 'Montserrat-Arabic',
          },
          body2: {
            fontSize: '0.9rem',
            '@media (min-width:600px)': {
              fontSize: '0.9rem',
            },
            '@media (min-width:900px)': {
              fontSize: '1rem',
            },
            '@media (min-width:1200px)': {
              fontSize: '1.3rem',
            },
            color: '#808080',
            whiteSpace: 'pre-line',
            textAlign: direction === 'rtl' ? 'right' : 'left',
            align: direction === 'rtl' ? 'right' : 'left',
            lineHeight: 1.2,
            direction: direction,

            fontFamily: 'Montserrat-Arabic',
          },
          h4: { direction: direction },
          languageButton: { backgroundColor: '#68B266' },
          subtitle1: {
            textAlign: direction === 'rtl' ? 'right' : 'left',
            align: direction === 'rtl' ? 'right' : 'left',
            lineHeight: 1.2,
            direction: direction,
            fontFamily: 'Montserrat-Arabic',
          },
        },
        custom: {
          rightLocation: [
            '50%',
            '25%',
            '30%',
            '70%',
            '60%',
            '1%',
            '2%',
            '5%',
            '10%',
            '40%',
          ],
        },
      }),
    [direction]
  )

  return <ThemeProvider theme={theme}>{children}</ThemeProvider>
}

export default ThemeProviderComponent
