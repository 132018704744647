import React from 'react'
import { Box, styled, keyframes } from '@mui/material'
const shineAnimation = keyframes`
  0% { transform: translateX(-100%); }
  100% { transform: translateX(100%); }
`

const BorderContainer = styled(Box)(
  ({ theme, borderRadius, /*borderWidth,*/ duration, color }) => ({
    position: 'relative',
    display: 'inline-flex',
    justifyContent: 'center',
    alignItems: 'center',
    padding: `${2}px`,
    borderRadius: `${borderRadius}px`,
    overflow: 'hidden',
    background: theme.palette.background.paper,
    height: '100%',
    '&::before': {
      content: '""',
      position: 'absolute',
      top: 0,
      left: 0,
      right: 0,
      bottom: 0,
      borderRadius: `${borderRadius}px`,
      border: `${2}px solid transparent`,
      backgroundImage: Array.isArray(color)
        ? `linear-gradient(45deg, ${color.join(', ')})`
        : color,
      backgroundClip: 'padding-box',
      boxSizing: 'border-box',
      animation: `${shineAnimation} ${duration}s linear infinite`,
    },
  })
)

function ShineBorder({
  borderRadius = 8,
  // borderWidth = 2,
  duration = 10,
  color = '#ff4081',
  children,
  sx,
}) {
  return (
    <BorderContainer
      borderRadius={borderRadius}
      //  borderWidth={borderWidth}
      duration={duration}
      color={Array.isArray(color) ? color : [color]}
      sx={{
        backgroundColor: 'transparent',
        ...sx,
      }}
    >
      <Box
        sx={{
          zIndex: 1,
          borderRadius: `${borderRadius - 2 /*borderWidth*/}px`,
          backgroundColor: 'transparent',
          height: '100%',
          width: '100%',
        }}
      >
        {children}
      </Box>
    </BorderContainer>
  )
}
export default ShineBorder
