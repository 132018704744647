import {
  Box,
  Typography,
  useTheme,
  useMediaQuery,
  Container,
} from '@mui/material'
import { useTranslation } from 'react-i18next'
import ERPSoftwareSolutionCards from './ERPSoftwareSolutionCards'

export default function ERPSoftwareSolution() {
  const theme = useTheme()
  const isMobile = useMediaQuery(theme.breakpoints.down('md'))
  const { t, i18n } = useTranslation()
  const isArabic = i18n.language === 'ar'
  const text = {
    titleGreen: t('products.erp_soft_title_green'),
    titleBlack: t('products.erp_soft_title_black'),
    titleSecGreen: t('products.erp_soft_title_sec_green'),
    titleSecBlack: t('products.erp_soft_title_sec_black'),
    body: t('products.erp_soft_para'),
  }
  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: isMobile ? 'column' : 'row', // Stack on mobile, row on larger screens
        alignItems: 'center',
        justifyContent: 'space-between',
        mb: '5%',
      }}
    >
      <Box
        sx={{
          mt: 2,
          flex: 1,
          padding: { xs: '1rem', md: '1rem', lg: '3rem' },
          maxWidth: { xs: '90%', md: '90%', lg: '65%', xl: '65%' },
        }}
      >
        <Typography
          variant="h3"
          color="secondary"
          sx={{
            fontSize: isArabic
              ? { xs: '1.6rem', md: '1.8rem', lg: '2.1rem' }
              : { xs: '1.4rem', md: '1.6rem', lg: '1.8rem', xl: '2rem' },
            whiteSpace: 'initial',
            mt: 2,
            padding: '0 0.5rem',
            justifyContent: 'center',
            align: 'center',
            fontWeight: 'bold',
          }}
          gutterBottom
        >
          {text.titleGreen}
        </Typography>
        <Typography
          variant="h2"
          sx={{
            fontSize: isArabic
              ? { xs: '1.6rem', md: '2rem', lg: '3rem' }
              : { xs: '2rem', md: '2.5rem', lg: '2.4rem', xl: '3rem' },
            // fontSize: '2.7rem',
            color: 'primary.contrastText',
            padding: '0 0.5rem',
          }}
        >
          {text.titleBlack}
          <Typography
            component="span"
            variant="h3"
            sx={{
              fontSize: isArabic
                ? { xs: '1.6rem', md: '2rem', lg: '3rem' }
                : { xs: '2rem', md: '2.5rem', lg: '2.4rem', xl: '3rem' },
              color: 'primary.contrastText',
              padding: { xs: '0 0 0', md: '0 0.5rem' },
              fontWeight: 'bold',
            }}
          >
            {text.titleSecBlack}
            <Typography
              component="span"
              variant="h3"
              sx={{
                fontSize: isArabic
                  ? { xs: '1.6rem', md: '2rem', lg: '3rem' }
                  : { xs: '2rem', md: '2.5rem', lg: '2.4rem', xl: '3rem' },
                padding: '0 0.5rem',
                fontWeight: 'bold',
              }}
              color="secondary"
            >
              {text.titleSecGreen}
            </Typography>
          </Typography>
        </Typography>
        <Typography
          variant="body2"
          mt="3%"
          sx={{
            //  fontSize: '1.5rem',
            whiteSpace: 'pre-line',
            padding: '0 0.5rem',
          }}
        >
          {text.body}
        </Typography>
      </Box>
      <Box
        sx={{
          mt: 2,
          flex: 1,
          padding: { xs: '1rem', md: '1rem', lg: '3rem' },
          maxWidth: { xs: '90%', md: '90%', lg: '35%', xl: '35%' },
        }}
      >
        <ERPSoftwareSolutionCards />
      </Box>
    </Box>
  )
}
