// src/components/Contact.js
import React from 'react'
import { Box, Button, Container, Typography } from '@mui/material'
import About from './HomeComponents/About'
import Main from './HomeComponents/Main'
import Products from './HomeComponents/Products'
import Services from './HomeComponents/Services'
import WhyUs from './HomeComponents/WhyUs'
import ContactUs from './HomeComponents/ContactUs'
import BackgroundCircle from '../common/BackgroundCircle'

import ERPSoftwareSolution from './Products/ERPSoftwareSolution'
function Home() {
  return (
    <Box>
      <Main />
      <About />
      <Products />
      <Services />
      <WhyUs />
      <ERPSoftwareSolution />
      <ContactUs />
    </Box>
  )
}

export default Home
