// src/i18n.js
import i18n from 'i18next'
import { initReactI18next } from 'react-i18next'

import { storageService } from './Services/StorageService'
// Translation files
import translationEN from './locales/en/translation.json'
import translationAR from './locales/ar/translation.json'

// Configuring i18next
i18n.use(initReactI18next).init({
  resources: {
    en: {
      translation: translationEN,
    },
    ar: {
      translation: translationAR,
    },
  },
  lng: storageService.getLanguage(), // Default language
  fallbackLng: 'en',
  interpolation: {
    escapeValue: false, // React already does escaping
  },
})

export default i18n
