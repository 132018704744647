import React, { Suspense } from 'react'
import { Routes, Route } from 'react-router-dom' // Import Routes and Route
import Navbar from './components/common/Navbar'
import Footer from './components/common/Footer'
import Home from './components/pages/Home'
import AboutUs from './components/pages/AboutUs'
import ContactUs from './components/pages/ContactUs'
import NotFound from './components/pages/NotFound'
import Products from './components/pages/Products'
import EInvoice from './components/pages/EInvoice'
import Services from './components/pages/Services'
import TestResponsive from './components/TestResponsive'
import BackgroundCircles from './components/common/BackgroundCircle'
import Scroll from './components/common/Scroll'
import './assests/styles/main.css'

function App() {
  return (
    <div className="background-pattern">
      <Navbar />

      <main>
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/home" element={<Home />} />
          <Route path="/aboutus" element={<AboutUs />} />
          <Route path="/contactus" element={<ContactUs />} />
          <Route path="/products" element={<Products />} />
          <Route path="/einvoicing" element={<EInvoice />} />
          <Route path="*" element={<Home />} />
          <Route path="/test" element={<TestResponsive />} />
          <Route path="/services" element={<Services />} />
        </Routes>
      </main>
      <Scroll />
      <Footer />
    </div>
  )
}

export default App
