import { Box, Button, Container, Typography } from '@mui/material'
import contactUs from '../../../assests/img/background/contactUs.png'
import mobileImg from '../../../assests/img/background/computer.png' // Image for mobile
import { useTheme, useMediaQuery } from '@mui/material'
import { useTranslation } from 'react-i18next'
import InTouch from './InTouch'

export default function Main() {
  const theme = useTheme()
  const isMobile = useMediaQuery(theme.breakpoints.down('md')) // Adjust the breakpoint

  const isLarge = useMediaQuery(theme.breakpoints.down('lg')) // Adjust the breakpoint
  const isExtraLarge = useMediaQuery(theme.breakpoints.down('xl'))
  const { t, i18n } = useTranslation()
  const isArabic = i18n.language === 'ar'
  return (
    <Box
      sx={{
        mt: '72px',
        display: 'flex',
        flexDirection: { xs: 'row', md: 'row', lg: 'column' },
        //isLarge ? 'column' : 'row', // Stack on mobile
        justifyContent: 'space-between',
        backgroundImage: {
          sx: 'none',
          md: 'none',
          lg: `url(${contactUs})`,
          xl: `url(${contactUs})`,
        },
        // Background image for larger screens
        backgroundPosition: 'right',
        backgroundRepeat: 'no-repeat',
        backgroundSize: 'contain',
        height: { sx: 'none', md: 'none', lg: '100vh' },
        //!isMobile ? '100vh' : 'none',
      }}
    >
      {/* Left Column: Text */}
      <Box
        sx={{
          mt: 10,
          flex: 1,
          padding: {
            sx: '1rem',
            md: '1rem',
            lg: '3rem',
          },
          //isMobile ? '1rem' : '3rem',
          maxWidth: { sx: '100%', md: '100%', lg: '100%', xl: '85%' },
          alignItems: 'center',
          justifyContent: 'space-between',
          backgroundSize: '50%',
          ml: {
            lg: 0,
          },
        }}
      >
        <Box
          sx={{
            ml: {
              lg: -20,
              xl: -10,
            },
          }}
        >
          <Typography
            align="center"
            variant="h4"
            component="h1"
            sx={{ mb: 0 }}
            fontWeight="fontWeightBold"
            gutterBottom
          >
            {/* {t('home.matrix')} */}
            {t('contact_us.get_in')}
            <Typography
              component="span"
              variant="h4"
              fontWeight="fontWeightBold"
              sx={{
                backgroundColor: 'secondary.main', // Highlight background
                color: 'primary.contrastText', // Highlight text color
                padding: '0 0.5rem',
                align: 'center',
                mb: '0', // Add padding for emphasis
              }}
            >
              {t('contact_us.touch')}
            </Typography>
            {t('contact_us.with_us')}
          </Typography>
          <Typography
            variant="body1"
            align="center"
            sx={{ whiteSpace: 'initial', m: 2 }}
          >
            {t('contact_us.wonder')}
          </Typography>
        </Box>
        <InTouch />
      </Box>
    </Box>
  )
}
