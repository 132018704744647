import React from 'react'
import { Box } from '@mui/material'
import EllipseYellow from '../../assests/img/EllipseYellow.png'
import EllipseGreen from '../../assests/img/EllipseGreen.png'

const BackgroundCircle = ({ Nb, position }) => {
  const circles = [EllipseYellow, EllipseGreen]
  console.log(position)
  //Function to generate random positions for the circles
  const getRandomPosition = () => {
    const x = Math.random() * 100 // Random percentage for left/right position
    const y = Math.random() * 100 // Random percentage for top/bottom position
    return { left: `${x}%`, top: `${y}%` }
  }

  return (
    <Box sx={{ position: 'relative' }}>
      <Box
        component="img"
        src={circles[Nb]}
        sx={{
          position: 'absolute',
          ...position,

          transform: 'rotate(45deg)',
          zIndex: 1,
        }}
      />
    </Box>
  )
}

export default BackgroundCircle

//import React, { useState, useEffect } from 'react'
//import { Box, Typography, Button, useTheme } from '@mui/material'

//const BackGround = () => {
// Track current language
// const theme = useTheme()
// const rightLocation = ['50%', '25%', '30%', '70%', '60%', '10%']
// const circles = [EllipseYellow, EllipseGreen]
// useEffect(() => {}, [])

//   return (
//     <Box sx={{ position: 'relative' }}>
//       {Array.from({ length: 3 }).map((_, index) => (
//         <Box
//           key={index}
//           component="img"
//           src={circles[index % circles.length]}
//           sx={{
//             position: 'absolute',
//             top: '1%',
//             left:
//               rightLocation[Math.floor(Math.random() * rightLocation.length)],
//             width: '80vw',
//             height: '80vh',

//             transform: 'rotate(45deg)',
//             zIndex: 1,
//           }}
//         />
//       ))}
//     </Box>
//   )
// }

//export default BackGround
