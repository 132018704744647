import React, { useState, useEffect, useMemo } from 'react'
import {
  Card,
  CardContent,
  Typography,
  Box,
  useTheme,
  useMediaQuery,
} from '@mui/material'
import { styled } from '@mui/system'

// Import all images
import cloud from '../../../assests/img/products/cloud.svg'
import codding from '../../../assests/img/products/codding.svg'
import cloudsupport from '../../../assests/img/products/cloudsupport.svg'
import humanResource from '../../../assests/img/products/humanResource.svg'
import calendar from '../../../assests/img/products/calendar.svg'
import check from '../../../assests/img/products/check.svg'
import encrypt from '../../../assests/img/products/encrypt.svg'
import secure from '../../../assests/img/products/secure.svg'
import news from '../../../assests/img/products/news.svg'
import crossplate from '../../../assests/img/products/crossplate.svg'

const CardWrapper = styled(Box)(({ theme, index, totalCards }) => {
  const angle = (index / (totalCards - 1)) * 180 - 90 // Keep this for rotation
  const scale = 1 - Math.abs((index / (totalCards - 1)) * 2 - 1) * 0.4 // Adjust scale factor for more variation
  const translateY = -300 + index * 30 // Vertical spacing based on index
  const translateX = (index - (totalCards - 1) / 2) * 100 // Horizontal spacing based on index

  return {
    position: 'absolute',
    transition: 'all 0.5s ease-out',
    transform: `
      rotate(${angle}deg)
      translateX(${translateX}px) // Added horizontal translation
      translateY(${translateY}px) // Vertical translation
      rotate(${-angle}deg)
     
    `,
    zIndex: totalCards - Math.abs(index - (totalCards - 1) / 2),
    [theme.breakpoints.down('sm')]: {
      transform: `
        rotate(${angle}deg)
        translateX(${translateX}px) // Keep horizontal translation for smaller screens
        translateY(${translateY}px) // Adjust for smaller screens
        rotate(${-angle}deg)
       
      `,
    },
  }
})

const CircleDecoration = styled(Box)(({ theme, color }) => ({
  position: 'absolute',
  top: '-50%',
  //  right: '10%',
  width: '100%',
  height: '100%',
  backgroundColor: color,
  borderRadius: '50%',
  zIndex: 0,
}))
const StyledCard = styled(Card)(({ theme, opacity, height, width }) => ({
  //  height: '70vh', // Set a larger percentage for height

  // '400px', // Optional: set max width
  // height: '300px', // Optional: set max height
  borderRadius: '16px',
  backgroundColor: theme.palette.background.paper,
  display: 'flex',
  flexDirection: 'column',
  boxShadow: theme.shadows[3],
  overflow: 'hidden',
  opacity: opacity,
  width: '400px', // Default width
  height: '300px', // Default height
  [theme.breakpoints.down('lg')]: {
    width: '300px',
    height: '200px',
  },
  [theme.breakpoints.down('md')]: {
    width: '300px',
    height: '200px',
  },
  [theme.breakpoints.down('sm')]: {
    width: '200px',
    height: '150px',
  },
}))

const Container = styled(Box)(({ theme }) => ({
  position: 'relative',
  width: '100%',
  height: '100vh', // Full viewport height
  margin: '50px auto',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  flexWrap: 'wrap', // Allow wrapping on smaller screens
  [theme.breakpoints.up('md')]: {
    width: '80%',
    height: '80vh', // Increase height on larger screens
  },
}))
const cardData = [
  {
    id: 1,
    title: 'AUTOMATED BACKUP',
    description:
      'Matrix ERP backups are maintained on a daily level, and we store data in different locations. Anyway, you can always manage your backups manually, if preferred.',
    icon: cloud,
  },
  {
    id: 2,
    title: 'SCALABILITY',
    description:
      'Big or small business, we provide different options that can be customized according to your need',
    icon: cloudsupport,
  },
  {
    id: 3,
    title: 'USER INTERFACE PACKS',
    description: 'Enable different languages on the user level.',
    icon: codding,
  },
  {
    id: 4,
    title: 'RELIABILITY',
    description: 'We guarantee data integrity and consolidation',
    icon: check,
  },
  {
    id: 5,
    title: 'DEVICES COMPATIBILITY',
    description:
      'Whether you are using PCs, tablets, or mobiles, our modules can work on different devices',
    icon: crossplate,
  },
  {
    id: 6,
    title: 'MANUFACTURING',
    description: 'Multiple manufacturing modes',
    icon: codding,
  },
  {
    id: 7,
    title: 'MANUFACTURING',
    description: 'Multiple manufacturing modes',
    icon: codding,
  },
  {
    id: 8,
    title: 'MANUFACTURING',
    description: 'Multiple manufacturing modes',
    icon: codding,
  },
  {
    id: 9,
    title: 'MANUFACTURING',
    description: 'Multiple manufacturing modes',
    icon: codding,
  },
  {
    id: 10,
    title: 'MANUFACTURING',
    description: 'Multiple manufacturing modes',
    icon: codding,
  },
  {
    id: 11,
    title: 'DEVICES COMPATIBILITY',
    description:
      'Whether you are using PCs, tablets, or mobiles, our modules can work on different devices',
    icon: check,
  },
]

const SemicircularCardList = () => {
  const [cards, setCards] = useState(cardData)
  const theme = useTheme()
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'))

  const yellowColors = useMemo(() => theme.palette.custom.yellowColors, [theme])
  const greenColors = useMemo(() => theme.palette.custom.greenColors, [theme])

  useEffect(() => {
    // const interval = setInterval(() => {
    //   setCards(prevCards => {
    //     const newCards = [...prevCards]
    //     const lastCard = newCards.pop()
    //     newCards.unshift(lastCard)
    //     return newCards
    //   })
    // }, 3000)
    // return () => clearInterval(interval)
  }, [])

  return (
    <Container>
      {cards.map((card, index) => (
        <CardWrapper key={card.id} index={index} totalCards={cards.length}>
          <StyledCard
            opacity={1 - Math.abs((index / (cards.length - 1)) * 2 - 1) * 0.5}
          >
            <Box sx={{ position: 'relative' }}>
              {/* Circle Box */}
              <Box
                sx={{
                  position: 'absolute',

                  borderRadius: '50%',
                  width: { xs: '40vw', md: '40vw', lg: '20vw' },
                  height: { xs: '40vw', md: '40vw', lg: '20vw' },
                  backgroundColor:
                    index % 2 === 0
                      ? yellowColors[
                          Math.floor(Math.random() * yellowColors.length)
                        ]
                      : greenColors[
                          Math.floor(Math.random() * greenColors.length)
                        ],
                  boxShadow: '0 2px 10px rgba(0, 0, 0, 0.2)', // Optional shadow effect for the circle
                  zIndex: 0, // Ensure the circle is behind the card content
                  transform: {
                    xs: 'translate(0,70%)',
                    md: 'translate(-30%,80%)',
                    lg: 'translate(40%,40%)',
                  }, // Center and rotate
                }}
              />
            </Box>
            <CardContent
              sx={{
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'space-between',
                zIndex: 1,
              }}
            >
              <Box sx={{ flexGrow: 1 }} />{' '}
              {/* This will push the logo to the bottom */}
              {/* Logo Wrapper */}
              <Box
                sx={{
                  position: 'absolute',
                  bottom: '10%', // Adjust as needed
                  right: '10%', // Adjust as needed
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',

                  borderRadius: '50%',
                  backgroundColor: 'white', // Circle color
                  boxShadow: '0 2px 5px rgba(0, 0, 0, 0.2)', // Optional shadow
                }}
              >
                <img
                  src={card.icon}
                  alt={card.title}
                  style={{
                    width: '100%', // Adjust size of the logo
                    height: 'auto',
                  }}
                />
              </Box>
              <Box>
                <Typography
                  variant={isMobile ? 'h6' : 'h5'}
                  sx={{ color: 'secondary.main', fontWeight: 'bold', mb: 1 }}
                >
                  {card.title}
                </Typography>
                <Typography variant="body2" sx={{ color: 'text.secondary' }}>
                  {card.description}
                </Typography>
              </Box>
            </CardContent>
          </StyledCard>
        </CardWrapper>
      ))}
    </Container>
  )
}

export default SemicircularCardList
