import { Box, Typography, useTheme, useMediaQuery } from '@mui/material'
import group from '../../../assests/img/group.png'
import { useTranslation } from 'react-i18next'
import BackgroundCircle from '../../common/BackgroundCircle'

export default function About() {
  const theme = useTheme()
  const isMobile = useMediaQuery(theme.breakpoints.down('md')) // Adjust the breakpoint
  const { t, i18n } = useTranslation()
  const isArabic = i18n.language === 'ar'

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: { xs: 'column', sm: 'column', md: 'row', lg: 'row' },
        //isMobile ? 'column' : 'row', // Stack on mobile
        alignItems: 'center',
        justifyContent: 'center',
        backgroundImage: {
          sx: 'none',
          sm: 'none',
          md: `url(${group})`,
          lg: `url(${group})`,
        },
        //!isMobile ? `url(${group})` : 'none', // Background image for larger screens
        backgroundPosition: 'right',
        backgroundRepeat: 'no-repeat',
        backgroundSize: '50%',
        height: { md: '100vh', lg: `none` },
        //!isMobile ? '100vh' : 'none',
      }}
    >
      <BackgroundCircle Nb={0} position={{ top: '0vh', left: '5vw' }} />
      <BackgroundCircle Nb={1} position={{ top: '-60vh', left: '50vw' }} />
      <BackgroundCircle Nb={1} position={{ top: '-10vh', right: '-100vw' }} />
      {/* Left Column: Text */}

      <Box
        sx={{
          flex: 1,
          padding: { xs: '1rem', md: '1rem', lg: '3rem' }, // isMobile ? '1rem' : '3rem',
          maxWidth: { xs: '100%', md: '100%', lg: '45%', xl: '50%' },
          // isMobile ? '100%' : isExtraLarge ? '100%' : '40%',
          justifyContent: 'center',
          alignItems: 'center',
          //justifyContent: 'space-between',
          backgroundSize: '50%',
        }}
      >
        <Box align="center">
          <Typography variant="h2" component="h1" gutterBottom>
            <Typography
              component="span"
              variant="h2"
              sx={{
                // Highlight background
                color: 'primary.contrastText', // Highlight text color
                padding: '0 0.0rem', // Add padding for emphasis
              }}
            >
              <Typography
                component="span"
                variant="h2"
                sx={{
                  backgroundColor: 'secondary.main', // Highlight background
                  color: 'primary.contrastText', // Highlight text color
                  padding: '0 0.0rem', // Add padding for emphasis
                }}
              >
                {t('home.who')}
              </Typography>
              <Typography
                component="span"
                variant="h2"
                sx={{
                  backgroundColor: 'transparent', // Highlight background
                  color: 'transparent', // Highlight text color
                  //  padding: '0 0.5rem', // Add padding for emphasis
                }}
              >
                {' '}
              </Typography>
            </Typography>
            <Typography component="span" variant="h2" sx={{ mb: 1 }}>
              {t('home.we_are')}
            </Typography>
          </Typography>
          {/* {t('home.business')} */}
          <Typography variant="body2">
            {/* {t('home.paragraph')} */}
            {t('home.who_para')}
          </Typography>
        </Box>
      </Box>
      {/* Right Column: Image */}
      {!isMobile ? (
        <Box sx={{ flex: 1 }}></Box>
      ) : (
        <Box
          sx={{
            width: '100%',
            mt: 4,
            mb: 4,
            alignContent: 'center',
            minHeight: '200px', // Ensures space is reserved for the image
            position: 'relative', // Enables positioning adjustments
          }}
        >
          <img
            src={group} // Image for mobile
            alt="Mobile App Showcase"
            style={{
              width: '100%',
              maxWidth: '100%',
              marginBottom: 4,
              height: 'auto',
            }} // Responsive styling
          />
        </Box>
      )}
    </Box>
  )
}
