import {
  Box,
  Typography,
  useTheme,
  useMediaQuery,
  Container,
} from '@mui/material'
import { useTranslation } from 'react-i18next'
import ServicesShuffle from './ServicesShuffle'
import BackgroundCircle from '../../common/BackgroundCircle'

export default function Services() {
  const theme = useTheme()
  const isMobile = useMediaQuery(theme.breakpoints.down('md')) // Adjust the breakpoint
  const { t, i18n } = useTranslation()
  const isArabic = i18n.language === 'ar'

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column', // Stack on mobile
        alignItems: 'center',
        justifyContent: 'space-between',
        mb: '5%',
      }}
    >
      <Box
        sx={{
          mt: 2,
          flex: 1,
          padding: { xs: '1rem', md: '1rem', lg: '3rem' },
          // isMobile ? '1rem' : '3rem',

          alignItems: 'center',
        }}
      >
        <Box align="center">
          <Typography variant="h2" component="h1" gutterBottom>
            <Typography
              component="span"
              variant="h2"
              sx={{
                color: 'primary.contrastText', // Highlight text color
                padding: '0 0.5rem',
                align: 'center',
                mb: '0', // Add padding for emphasis
              }}
            >
              {' '}
              {t('home.our')}
            </Typography>
          </Typography>
          <Typography
            component="span"
            variant="h2"
            sx={{
              backgroundColor: 'secondary.main', // Highlight background
              color: 'primary.contrastText', // Highlight text color
              padding: '0 0.5rem', // Add padding for emphasis
            }}
          >
            {t('home.service')}
          </Typography>
          {/* {t('home.business')} */}
          <Typography variant="body1" mt="3%">
            {/* {t('home.paragraph')} */}
            {t('home.service_para')}
          </Typography>
        </Box>
      </Box>
      <ServicesShuffle />
      <BackgroundCircle Nb={1} position={{ top: '20vh', right: '30vw' }} />
      <BackgroundCircle Nb={1} position={{ top: '-40vh', left: '30vw' }} />
      <BackgroundCircle Nb={0} position={{ top: '-30vh', right: '30vw' }} />
    </Box>
  )
}
