import React from 'react'
import { useTheme } from '@mui/material/styles'
import useMediaQuery from '@mui/material/useMediaQuery'
import { Typography, Box } from '@mui/material'

const TestResponsive = () => {
  // src/components/ResponsiveDebugger.js

  const theme = useTheme()
  const isXs = useMediaQuery(theme.breakpoints.only('xs'))
  const isSm = useMediaQuery(theme.breakpoints.only('sm'))
  const isMd = useMediaQuery(theme.breakpoints.only('md'))
  const isLg = useMediaQuery(theme.breakpoints.only('lg'))
  const isXl = useMediaQuery(theme.breakpoints.only('xl'))

  const currentBreakpoint = isXs
    ? 'xs'
    : isSm
    ? 'sm'
    : isMd
    ? 'md'
    : isLg
    ? 'lg'
    : isXl
    ? 'xl'
    : 'unknown'

  return (
    <Box
      sx={{
        position: 'fixed',
        bottom: 0,
        right: 0,
        backgroundColor: 'rgba(0,0,0,0.7)',
        color: '#fff',
        padding: '0.5rem',
        borderRadius: '4px',
        zIndex: 1300, // Above other elements
      }}
    >
      <Box sx={{ p: 2 }}>
        <Typography variant="h1">Heading 1</Typography>
        <Typography variant="h2">Heading 2</Typography>
        <Typography variant="h3">Heading 3</Typography>
        <Typography variant="body1">
          This is body1 text. Lorem ipsum dolor sit amet.
        </Typography>
        <Typography variant="body2">
          This is body2 text. Lorem ipsum dolor sit amet.
        </Typography>
      </Box>
      <Typography variant="body2">Breakpoint: {currentBreakpoint}</Typography>
    </Box>
  )
}

export default TestResponsive
