import { Box, Typography, useTheme, useMediaQuery } from '@mui/material'
import { useTranslation } from 'react-i18next'

import React, { useState, useRef, useEffect } from 'react'
import '../../assests/styles/main.css'
import BackgroundCircle from './BackgroundCircle'
import { getTextDirection } from '../../utils/getTextDirection'
export default function ImgRight({ title, text, imgsrc }) {
  const theme = useTheme()
  const isMobile = useMediaQuery(theme.breakpoints.down('md')) // Adjust the breakpoint
  const { t, i18n } = useTranslation()
  const isArabic = i18n.language === 'ar'
  const [isVisible, setIsVisible] = useState(false)
  const [isSlidingOut, setIsSlidingOut] = useState(false)
  const ref = useRef(null)
  const direction = getTextDirection(i18n.language)
  const isLarge = useMediaQuery(theme.breakpoints.down('lg'))
  const isSmall = useMediaQuery(theme.breakpoints.down('sm'))
  useEffect(() => {
    const observer = new IntersectionObserver(
      ([entry]) => {
        setIsVisible(entry.isIntersecting)
        // Reset slide-out when visible
      },
      {
        threshold: isLarge ? (isSmall ? 0.1 : 0.3) : 0.5, // Trigger when 20% is visible
      }
    )

    const currentRef = ref.current

    if (currentRef) {
      observer.observe(currentRef) // Start observing the current ref
      console.log('Observing:', currentRef) // Log the observed element
    }

    return () => {
      if (currentRef) {
        observer.unobserve(currentRef) // Clean up the observer
        console.log('Stopped observing:', currentRef) // Log when stopped observing
      }
    }
  }, [])
  return (
    <Box ref={ref}>
      <Box
        align="center"
        // Set the ref to the Box
        className={
          isVisible ? 'slide-in-from-left' : 'slide-out-from-left'
          // Apply animation classes
        }
        sx={{
          display: 'flex',
          flexDirection: { xs: 'column', sm: 'column', md: 'row', lg: 'row' },
          //isMobile ? 'column' : 'row', // Stack on mobile
          alignItems: 'center',
          justifyContent: 'space-between',
          backgroundImage: {
            sx: 'none',
            sm: 'none',
            md: `url(${imgsrc})`,
            lg: `url(${imgsrc})`,
          },
          //!isMobile ? `url(${imgsrc})` : 'none', // Background image for larger screens
          backgroundPosition: 'right',
          backgroundRepeat: 'no-repeat',
          backgroundSize: '30%',
          height: { md: '60vh', lg: `none` },
          mr: { md: '10%', lg: `5%` },
          //!isMobile ? '100vh' : 'none',
        }}
      >
        {/* Left Column: Text */}
        <Box
          sx={{
            mt: 2,
            flex: 1,
            padding: { xs: '1rem', md: '1rem', lg: `3rem` }, //isMobile ? '1rem' : '3rem',
            width: { xs: '75%', md: '50%', lg: `50%` },
            margin: { xs: '0 1rem', md: '0 2rem' },
            //mr: { md: 5, lg: 20 },
          }}
        >
          <Typography
            variant="h2"
            component="h1"
            gutterBottom
            sx={{ pb: 3, fontWeight: 'bold' }}
          >
            {title}
          </Typography>
          {/* {t('home.business')} */}
          <Typography
            variant="body2"
            align={direction === 'rtl' ? 'right' : 'left'}
            sx={{ whiteSpace: 'pre-line' /*fontSize: '1.1rem'*/ }}
          >
            {/* {t('home.paragraph')} */}
            {text}
          </Typography>
        </Box>
        <BackgroundCircle Nb={0} position={{ top: '-50vh', right: '33vw' }} />

        <BackgroundCircle Nb={1} position={{ top: '0vh', right: '30vw' }} />

        {/* <BackgroundCircle Nb={1} position={{ top: '-65vh', right: '-25vw' }} /> */}
        {/* Right Column: Image */}
        {!isMobile ? (
          <Box sx={{ flex: 1 }}></Box>
        ) : (
          <Box
            sx={{
              width: '100%',
              mt: 4,
              mb: 4,
              alignContent: 'center',
              minHeight: '200px', // Ensures space is reserved for the image
              position: 'relative', // Enables positioning adjustments
            }}
          >
            <img
              src={imgsrc} // Image for mobile
              alt="Mobile App Showcase"
              style={{
                width: '100%',
                maxWidth: '100%',
                marginBottom: 4,
                height: 'auto',
              }} // Responsive styling
            />
          </Box>
        )}
      </Box>
    </Box>
  )
}
