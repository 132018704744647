import React, { useState, useEffect } from 'react'
import { Box, Typography, Button, useTheme } from '@mui/material'
import { useTranslation } from 'react-i18next'
import SaudiFlag from '../../assests/img/saudiflag.png'
import BritishFlag from '../../assests/img/britishflag.png'
import { storageService } from '../../Services/StorageService'

// Add more languages as needed
const languagesList = [
  { language: 'en', label: 'EN', flag: BritishFlag },
  { language: 'ar', label: 'AR', flag: SaudiFlag },
  // Add more languages here, e.g., French, Spanish, etc.
  // { language: 'fr', label: 'FR', flag: FrenchFlag },
  // { language: 'es', label: 'ES', flag: SpanishFlag },
]

const LanguageToggle = () => {
  const { i18n } = useTranslation()
  const [currentLanguage, setCurrentLanguage] = useState(i18n.language) // Track current language
  const theme = useTheme()

  // Function to cycle through available languages
  const handleToggleLanguage = () => {
    const currentIndex = languagesList.findIndex(
      lang => lang.language === currentLanguage
    )
    const nextIndex = (currentIndex + 1) % languagesList.length // Cycle to the next language
    const nextLanguage = languagesList[nextIndex].language
    i18n.changeLanguage(nextLanguage) // Change language using i18next
    setCurrentLanguage(nextLanguage) // Update the state with the new language
    storageService.saveLanguage(nextLanguage)
  }

  useEffect(() => {
    setCurrentLanguage(i18n.language) // Set initial language from i18n
  }, [i18n.language])

  // Get the current language object for display
  const currentLangObj = languagesList.find(
    lang => lang.language === currentLanguage
  )

  return (
    <Button
      onClick={handleToggleLanguage}
      sx={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        backgroundColor: 'languageButton.backgroundColor',
        borderRadius: '30px',
        border: `2px solid ${theme.palette.primary.main}`,
        padding: '5px 15px',
        minWidth: '100px',
        height: '40px',
        textTransform: 'none',
        '&:hover': {
          backgroundColor: 'Navbar.LoginButton.hover.background',
        },
      }}
    >
      <Box
        sx={{
          width: '25px',
          height: '20px',
          backgroundImage: `url(${currentLangObj.flag})`,
          backgroundSize: 'cover',
          backgroundPosition: 'center',
          borderRadius: '5px',
          marginRight: '10px',
        }}
      />
      <Typography
        variant="body2"
        sx={{
          // color: '#FFFFFF',
          fontWeight: 600,
          fontSize: '16px',
        }}
      >
        {currentLangObj.label}
      </Typography>
    </Button>
  )
}

export default LanguageToggle
