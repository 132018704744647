import React, { useState } from 'react'
import {
  Box,
  Card,
  CardContent,
  Typography,
  IconButton,
  Modal,
  Button,
  useTheme,
  Container,
} from '@mui/material'
import { Info as InfoIcon } from '@mui/icons-material'
import rocketIcon from '../../../assests/img/icons/rocketIcon.svg'
import badgeIcon from '../../../assests/img/icons/badgeIcon.svg'
import CheckIcon from '../../../assests/img/icons/CheckIcon.svg'
import { keyframes } from '@mui/system'
import { useTranslation } from 'react-i18next'

export default function CustomDevCards() {
  const theme = useTheme()
  const rightLocation = theme.custom.rightLocation
  const whiteShades = theme.palette.custom.whiteShades
  const { t, i18n } = useTranslation()
  const isArabic = i18n.language === 'ar'
  const spin = keyframes`
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
`
  const float = keyframes`
  0%, 100% { transform: translateY(0); }
  50% { transform: translateY(-10px); }
`
  const papersContents = [
    {
      title: t('services.mobile_app'),
      description: t('services.mobile_app_para'),
    },
    {
      title: t('services.web_app'),
      description: t('services.web_app_para'),
    },
    {
      title: t('services.desktop_app'),
      description: t('services.desktop_app_para'),
    },
    /*
     {
      title: 'Data-Driven Insights',
      description: 'The program provides valuable analytics that help companies understand customer behaviors and preferences, enabling them to fine-tune their marketing strategies and improve overall customer satisfaction.',
    },
    */
  ]
  const papersContentsTwo = [
    {
      title: t('services.testing'),
      description: t('services.testing_para'),
    },
    {
      title: t('services.migration'),
      description: t('services.migration_para'),
    },
  ]
  return (
    <Container sx={{ padding: 1 /*maxWidth: '90vw' */ }}>
      <Box
        sx={{
          padding: 1,
          boxSizing: 'border-box',
          display: 'grid',
          gridTemplateColumns: {
            xs: '1fr',
            md: '2fr 2fr 2fr',
            lg: '2fr 2fr 2fr',
          },
          gap: 10,
          mb: 10,
          width: '100%',
        }}
      >
        {/* Second row: 3 cards evenly split */}
        {papersContents.map((papersContent, index) => (
          <Card
            key={index}
            sx={{
              width: '100%',
              height: '100%',
              borderRadius: '16px',
              backgroundColor:
                whiteShades[Math.floor(Math.random() * whiteShades.length)],
              display: 'flex',
              flexDirection: 'column',
              boxShadow: 3,
              overflow: 'hidden',
              position: 'relative', // Set position relative for absolute children
              border: '2px solid transparent',
              transition: 'all 0.3s ease-in-out',
              animation: `${float} 2s infinite`,
              '&:hover': {
                borderColor: theme => theme.palette.secondary.main,
                boxShadow: theme => `0 0 15px ${theme.palette.secondary.main}`,
                '& .hover-box': {
                  borderBottomColor: theme => theme.palette.secondary.main,
                  boxShadow: theme =>
                    `0 0 10px ${theme.palette.secondary.main}`,
                },
              },
              //   '&:hover': {
              //     borderColor: theme => theme.palette.secondary.main,
              //     boxShadow: theme => `0 0 15px ${theme.palette.secondary.main}`,
              //     '& .hover-box': {
              //       borderBottomColor: theme => theme.palette.secondary.main,
              //       boxShadow: theme =>
              //         `0 0 10px ${theme.palette.secondary.main}`,
              //     },
              // },
            }}
          >
            <Box
              sx={{
                position: 'relative', // Set position relative for absolute children
              }}
            >
              <Box
                className="hover-box"
                sx={{
                  position: 'absolute',
                  top: '1%', // Adjust the position from the top
                  right:
                    rightLocation[
                      Math.floor(Math.random() * rightLocation.length)
                    ], // Adjust the position from the right
                  width: 0, // Width of the box, will be set by borders
                  height: 0, // Height of the box, will be set by borders
                  border: '30vw solid transparent',
                  borderBottom: `30vw solid ${
                    whiteShades[Math.floor(Math.random() * whiteShades.length)]
                  }`, // Bottom border for the triangle
                  backgroundColor:
                    whiteShades[Math.floor(Math.random() * whiteShades.length)],
                  boxShadow: '0 2px 10px rgba(0, 0, 0, 0.2)', // Optional shadow effect
                  zIndex: 0, // Ensure it is on top of the parent box
                  transform: 'rotate(0deg)', // Remove rotation or adjust as needed
                  animation: `${spin} 5s infinite ease`, // Keep the animation if needed
                  // borderRadius: '50%',
                }}
              />
            </Box>
            <CardContent
              sx={{
                display: 'flex',
                flexDirection: 'row',
                alignItems: 'center', // Center items vertically
                justifyContent: 'center', // Center items horizontally
                zIndex: 1,
              }}
            >
              <Box
                sx={{
                  flexGrow: 1,
                  margin: '1em',
                  textAlign: 'center', // Center text inside the Box
                }}
              >
                <Typography
                  variant="h4"
                  sx={{ color: 'secondary', fontWeight: 'bold', mt: 1 }}
                >
                  {papersContent.title}
                </Typography>
                <Typography
                  variant="h5"
                  component={'h5'}
                  sx={{ color: 'secondary.main', fontWeight: 'bold', mt: 1 }}
                >
                  {papersContent.price}
                </Typography>
                <Typography variant="body2" sx={{ color: 'secondary', mt: 1 }}>
                  {papersContent.description}
                </Typography>
              </Box>
            </CardContent>
          </Card>
        ))}
      </Box>
      <Box
        sx={{
          padding: 1,
          boxSizing: 'border-box',
          display: 'grid',
          gridTemplateColumns: {
            xs: '1fr',
            md: '2fr 2fr',
            lg: '2fr 2fr',
          },
          gap: 10,
          mb: 10,
          width: '100%',
        }}
      >
        {/* Second row: 3 cards evenly split */}
        {papersContentsTwo.map((papersContent, index) => (
          <Card
            key={index}
            sx={{
              width: '100%',
              height: '100%',
              borderRadius: '16px',
              backgroundColor:
                whiteShades[Math.floor(Math.random() * whiteShades.length)],
              display: 'flex',
              flexDirection: 'column',
              boxShadow: 3,
              overflow: 'hidden',
              position: 'relative', // Set position relative for absolute children
              border: '2px solid transparent',
              transition: 'all 0.3s ease-in-out',
              animation: `${float} 3s infinite`,
              '&:hover': {
                borderColor: theme => theme.palette.secondary.main,
                boxShadow: theme => `0 0 15px ${theme.palette.secondary.main}`,
                '& .hover-box': {
                  borderBottomColor: theme => theme.palette.secondary.main,
                  boxShadow: theme =>
                    `0 0 10px ${theme.palette.secondary.main}`,
                },
              },
              //   '&:hover': {
              //     borderColor: theme => theme.palette.secondary.main,
              //     boxShadow: theme => `0 0 15px ${theme.palette.secondary.main}`,
              //     '& .hover-box': {
              //       borderBottomColor: theme => theme.palette.secondary.main,
              //       boxShadow: theme =>
              //         `0 0 10px ${theme.palette.secondary.main}`,
              //     },
              // },
            }}
          >
            <Box
              sx={{
                position: 'relative', // Set position relative for absolute children
              }}
            >
              <Box
                className="hover-box"
                sx={{
                  position: 'absolute',
                  top: '1%', // Adjust the position from the top
                  right:
                    rightLocation[
                      Math.floor(Math.random() * rightLocation.length)
                    ], // Adjust the position from the right
                  width: 0, // Width of the box, will be set by borders
                  height: 0, // Height of the box, will be set by borders
                  border: '30vw solid transparent',
                  borderBottom: `30vw solid ${
                    whiteShades[Math.floor(Math.random() * whiteShades.length)]
                  }`, // Bottom border for the triangle
                  backgroundColor:
                    whiteShades[Math.floor(Math.random() * whiteShades.length)],
                  boxShadow: '0 2px 10px rgba(0, 0, 0, 0.2)', // Optional shadow effect
                  zIndex: 0, // Ensure it is on top of the parent box
                  transform: 'rotate(0deg)', // Remove rotation or adjust as needed
                  animation: `${spin} 7s infinite ease`, // Keep the animation if needed
                  // borderRadius: '50%',
                }}
              />
            </Box>
            <CardContent
              sx={{
                display: 'flex',
                flexDirection: 'row',
                alignItems: 'center', // Center items vertically
                justifyContent: 'center', // Center items horizontally
                zIndex: 1,
              }}
            >
              <Box
                sx={{
                  flexGrow: 1,
                  margin: '1em',
                  textAlign: 'center', // Center text inside the Box
                }}
              >
                <Typography
                  variant="h4"
                  sx={{ color: 'secondary', fontWeight: 'bold', mt: 1 }}
                >
                  {papersContent.title}
                </Typography>
                <Typography
                  variant="h5"
                  component={'h5'}
                  sx={{ color: 'secondary.main', fontWeight: 'bold', mt: 1 }}
                >
                  {papersContent.price}
                </Typography>
                <Typography variant="body2" sx={{ color: 'secondary', mt: 1 }}>
                  {papersContent.description}
                </Typography>
              </Box>
            </CardContent>
          </Card>
        ))}
      </Box>
    </Container>
  )
}
