import { Box, Typography, useTheme } from '@mui/material'
import { useTranslation } from 'react-i18next'
import ERPConsulationCard from './ERPConsulationCard'
import BackgroundCircle from '../../common/BackgroundCircle'
export default function ERPConsulation() {
  const theme = useTheme()
  //const isMobile = useMediaQuery(theme.breakpoints.down('md')) // Adjust the breakpoint
  const { t, i18n } = useTranslation()
  const isArabic = i18n.language === 'ar'

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column', // Stack on mobile
        alignItems: 'center',
        justifyContent: 'space-between',
        mb: '5%',
        width: '100%',
      }}
    >
      <Box
        sx={{
          mt: 2,
          flex: 1,
          // padding: isMobile ? '1rem' : '3rem',
          padding: { xs: '1rem', md: '1rem', lg: '3rem' },
          alignItems: 'center',
        }}
      >
        <Box align="center">
          <Typography variant="h1" component="h1" gutterBottom>
            <Typography
              component="span"
              variant="h2"
              sx={{
                backgroundColor: 'secondary.main', // Highlight background
                color: 'primary.contrastText', // Highlight text color
                padding: '0 0.5rem',
                align: 'center',
                mb: '0', // Add padding for emphasis
              }}
            >
              {t('services.erp_consulting_services')}
            </Typography>
          </Typography>
          <Typography
            component="span"
            variant="h2"
            sx={{
              color: 'primary.contrastText', // Highlight text color
              padding: '0 0.5rem', // Add padding for emphasis
            }}
          >
            {t('services.erp_consulting_transformation')}
          </Typography>
          {/* {t('home.business')} */}
        </Box>
      </Box>
      {/* <BackgroundCircle Nb={1} position={{ top: '-40vh', left: '20vw' }} />
      <BackgroundCircle Nb={0} position={{ top: '-30vh', right: '30vw' }} />*/}
      <ERPConsulationCard />
    </Box>
  )
}
