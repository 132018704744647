// src/components/Contact.js
import React from 'react'
import { Box, Button, Container, Typography } from '@mui/material'
import ImgRight from '../common/ImgRight'
import ImgLeft from '../common/ImgLeft'
import IconCloud from './ContactUsComponents/Globe_copy'
import { SlidingIcons } from './ContactUsComponents/SlidingIcons'
import IconM from './ContactUsComponents/M'
import vision from '../../assests/img/aboutus/vision.svg'
import mission from '../../assests/img/aboutus/mission.svg'
import ethics from '../../assests/img/aboutus/ethics.svg'
import group from '../../assests/img/group.png'
import { useTranslation } from 'react-i18next'
function AboutUs() {
  const { t, i18n } = useTranslation()
  const isArabic = i18n.language === 'ar'
  const data = [
    {
      title: t('about_us.about_us_title'),
      text: t('about_us.about_us_para'),
      img: group,
    },
    {
      title: t('about_us.our_vision_title'),
      text: t('about_us.our_vision_para'),
      img: vision,
    },
    {
      title: t('about_us.our_mision_title'),
      text: t('about_us.our_mision_para'),
      img: mission,
    },
    {
      title: t('about_us.core_values_title'),
      text: t('about_us.core_values_para'),
      img: ethics,
    },
  ]

  const logos = require.context(
    '../../assests/img/logos',
    false,
    /\.(png|jpe?g|svg)$/ // Ensure the path and file extensions are correct
  )
  const logoList = logos.keys().map(logos)
  return (
    <>
      <Box mt="72px">
        <ImgRight
          text={data[0].text}
          imgsrc={data[0].img}
          title={data[0].title}
        />
        <ImgLeft
          text={data[1].text}
          imgsrc={data[1].img}
          title={data[1].title}
        />
        <ImgRight
          text={data[2].text}
          imgsrc={data[2].img}
          title={data[2].title}
        />
        <ImgLeft
          text={data[3].text}
          imgsrc={data[3].img}
          title={data[3].title}
        />
      </Box>

      <SlidingIcons icons={logoList} />
    </>
  )
}

export default AboutUs
