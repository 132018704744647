import React, { useState, useEffect } from 'react'
import { Avatar, Box, Typography, Card, CardContent } from '@mui/material'
import Marquee from 'react-fast-marquee'
import { useTranslation } from 'react-i18next'
import { getTextDirection } from '../../../utils/getTextDirection'
import { keyframes } from '@mui/system'
import matrixLogo from '../../../assests/img/logo.png'
import cloud from '../../../assests/img/products/cloud.svg'
import codding from '../../../assests/img/products/codding.svg'
import cloudsupport from '../../../assests/img/products/cloudsupport.svg'
import humanResource from '../../../assests/img/products/humanResource.svg'
import calendar from '../../../assests/img/products/calendar.svg'
import check from '../../../assests/img/products/check.svg'
import encrypt from '../../../assests/img/products/encrypt.svg'
import secure from '../../../assests/img/products/secure.svg'
import news from '../../../assests/img/products/news.svg'
import crossplate from '../../../assests/img/products/crossplate.svg'
import customersupport from '../../../assests/img/products/CustomerSupport.svg'

const ReviewCard = ({ icon, title, description }) => {
  const { i18n } = useTranslation()
  const direction = getTextDirection(i18n.language)
  const logoCount = 1
  const backgroundImageUrl = 'https://example.com/background-image.png' // Replace with your image URL

  return (
    <Card
      sx={{
        width: { xs: '200px', sm: '200px', md: '300px', lg: '400px' },
        height: '200px',
        borderRadius: 2,
        boxShadow: 3,
        p: 2,
        backgroundColor: 'background.paper',
        cursor: 'pointer',
        m: 1,
        position: 'relative',
        overflow: 'hidden',
        '&:hover': {
          backgroundColor: 'grey.100',
          borderColor: theme => theme.palette.secondary.main,
          boxShadow: theme => `0 0 15px ${theme.palette.secondary.main}`,
          '& .hover-box': {
            borderBottomColor: theme => theme.palette.secondary.main,
            boxShadow: theme => `0 0 10px ${theme.palette.secondary.main}`,
          },
        },
      }}
    >
      <Box
        display="flex"
        justifyContent={direction === 'rtl' ? 'flex-end' : 'flex-start'}
        gap={2}
        sx={{ position: 'relative', zIndex: 1 }}
      >
        {direction !== 'rtl' && <Avatar alt={title} src={icon} />}
        <Box>
          <Typography
            variant="h3"
            fontWeight="bold"
            sx={{ fontSize: '1.2rem', backgroundColor: 'background.paper' }}
            align={direction === 'rtl' ? 'right' : 'left'}
          >
            {title}
          </Typography>
        </Box>
        {direction === 'rtl' && <Avatar alt={title} src={icon} />}
      </Box>
      <CardContent sx={{ position: 'relative', zIndex: 1 }}>
        <Typography
          variant="body2"
          align={direction === 'rtl' ? 'right' : 'left'}
          sx={{ backgroundColor: 'background.paper' }}
        >
          {description}
        </Typography>
      </CardContent>
    </Card>
  )
}

export function MarqueeDemo() {
  const { t, i18n } = useTranslation()
  const isArabic = i18n.language === 'ar'
  const cardData = [
    {
      id: 2,
      title: t('products.scalability_title'),
      description: t('products.scalability_para'),
      icon: cloudsupport,
    },
    {
      id: 4,
      title: t('products.reliability_title'),
      description: t('products.reliability_para'),
      icon: check,
    },
    {
      id: 5,
      title: t('products.devices_compatibility_title'),
      description: t('products.devices_compatibility_para'),
      icon: crossplate,
    },
    {
      id: 6,
      title: t('products.continuous_support_title'),
      description: t('products.continuous_support_para'),
      icon: customersupport,
    },
    {
      id: 9,
      title: t('products.data_privacy_title'),
      description: t('products.data_privacy_para'),
      icon: secure,
    },
  ]
  const cardDataTwo = [
    {
      id: 1,
      title: t('products.automated_backup_title'),
      description: t('products.automated_backup_para'),
      icon: cloud,
    },
    {
      id: 3,
      title: t('products.user_interface_packs_title'),
      description: t('products.user_interface_packs_para'),
      icon: codding,
    },
    {
      id: 7,
      title: t('products.encrypted_communication_title'),
      description: t('products.encrypted_communication_para'),
      icon: encrypt,
    },
    {
      id: 8,
      title: t('products.user_permissions_title'),
      description: t('products.user_permissions_para'),
      icon: humanResource,
    },
  ]
  const [mounted, setMounted] = useState(false)

  useEffect(() => {
    setMounted(true)
  }, [])

  if (!mounted) {
    return null
  }

  const firstRow = cardDataTwo
  const secondRow = cardData

  return (
    <Box
      className="hover-box"
      sx={{
        position: 'relative',
        height: 500,
        width: '90vw',
        overflow: 'hidden',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        borderRadius: 2,
        border: 1,
        borderColor: 'grey.300',
        backgroundColor: 'background.default',
        boxShadow: 3,
        mt: 4,
      }}
    >
      <Marquee pauseOnClick gradient={false} speed={20} direction="left">
        {firstRow.map(review => (
          <ReviewCard key={review.id} {...review} />
        ))}
      </Marquee>
      <Marquee pauseOnClick gradient={false} speed={20} direction="right">
        {secondRow.map(review => (
          <ReviewCard key={review.id} {...review} />
        ))}
      </Marquee>
    </Box>
  )
}
