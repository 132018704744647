import React from 'react'
import ReactDOM from 'react-dom/client'
import App from './App'
import { BrowserRouter } from 'react-router-dom'
import theme from './theme'
import { ThemeProvider } from '@mui/material/styles'
import ThemeProviderComponent from './theme'
import './i18n'
import * as serviceWorkerRegistration from './serviceWorkerRegistration'

const resizeObserverLoopErrRe = /^ResizeObserver loop limit exceeded/
const onResizeObserverLoopError = function (err) {
  if (resizeObserverLoopErrRe.test(err.message)) {
    // Ignored ResizeObserver loop limit exceeded error
    console.warn('Ignored ResizeObserver loop limit exceeded error')
  } else {
    console.error(err)
  }
}
window.addEventListener('error', onResizeObserverLoopError)
const root = ReactDOM.createRoot(document.getElementById('root'))
root.render(
  <React.StrictMode>
    <BrowserRouter>
      <ThemeProviderComponent theme={theme}>
        <App />
      </ThemeProviderComponent>
    </BrowserRouter>
  </React.StrictMode>
)
serviceWorkerRegistration.register()
// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
