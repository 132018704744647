import { Box, Card, CardContent, Typography } from '@mui/material'
import { useTheme, useMediaQuery } from '@mui/material'
import { useTranslation } from 'react-i18next'
import React, { useState } from 'react'

import BackgroundCircle from '../../common/BackgroundCircle'
import business from '../../../assests/img/contactUs/business.png'
import cash from '../../../assests/img/contactUs/cash.png'
import handshake from '../../../assests/img/contactUs/handshake.png'
import phone from '../../../assests/img/contactUs/phone.png'

export default function InTouch() {
  const theme = useTheme()
  const isMobile = useMediaQuery(theme.breakpoints.down('md')) // Adjust the breakpoint

  const isLarge = useMediaQuery(theme.breakpoints.down('lg')) // Adjust the breakpoint
  const isExtraLarge = useMediaQuery(theme.breakpoints.down('xl'))
  const { t, i18n } = useTranslation()
  const isArabic = i18n.language === 'ar'
  const papersContent = [
    {
      title: t('contact_us.sales_title'),
      description: t('contact_us.sales_para'),
    },
    {
      title: t('contact_us.career_title'),
      description: t('contact_us.career_para'),
    },
    {
      title: t('contact_us.partners_title'),
      description: t('contact_us.partners_para'),
    },
    {
      title: t('contact_us.support_title'),
      description: t('contact_us.support_para'),
    },
  ]
  return (
    <Box
      sx={{
        mt: '10vh',

        ml: { md: '0px', lg: '10vh' },
        maxWidth: { md: '100%', lg: '80%' },
        padding: 1,
        display: 'grid',
        gridTemplateColumns: {
          xs: '1fr',
          md: '2fr 2fr',
          lg: '2fr 2fr 2fr 2fr',
        },
        gap: 2,
      }}
    >
      {/* Second row: 3 cards evenly split */}
      {[0, 1, 2, 3].map(index => (
        <Card
          key={index}
          sx={{
            height: '100%',
            display: 'flex',
            flexDirection: 'column',
            backgroundColor: 'transparent',
            overflow: 'hidden',
            position: 'relative', // Set position relative for absolute children
            border: 'none',
            boxShadow: 'none',
          }}
        >
          <Box
            sx={{
              position: 'relative', // Set position relative for absolute children
            }}
          ></Box>
          <CardContent
            sx={{
              display: 'flex',
              flexDirection: 'row',
              alignItems: 'center', // Center items vertically
              justifyContent: 'center', // Center items horizontally
              zIndex: 1,
            }}
          >
            <Box
              sx={{
                flexGrow: 1,
                margin: '1em',
                textAlign: 'center', // Center text inside the Box
              }}
            >
              <img
                src={[cash, business, handshake, phone][index]}
                alt="icon"
                style={{
                  display: 'block',
                  margin: '0 auto',
                  height: '20%',
                }} // Center the icon horizontally
              />
              <Typography
                variant="h4"
                sx={{ color: 'secondary', fontWeight: 'bold' }}
              >
                {papersContent[index].title}
              </Typography>
              <Typography variant="body2" sx={{ color: 'secondary' }}>
                {papersContent[index].description}
              </Typography>
            </Box>
          </CardContent>
        </Card>
      ))}
    </Box>
  )
}
