const STORAGE_KEY_LANGUAGE = 'LANGUAGE'
//const STORAGE_KEY_USERPERMISSION = 'USERPERMISSION'
export const storageService = {
  // Get all items from localStorage
  getLanguage: () => {
    const data = localStorage.getItem(STORAGE_KEY_LANGUAGE)
    return data // Return empty array if no data exists
  },

  // Save an item to localStorage
  saveLanguage: lang => {
    localStorage.setItem(STORAGE_KEY_LANGUAGE, lang)
  },
}
