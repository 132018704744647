import React, { useEffect, useState } from 'react'
import Box from '@mui/material/Box'
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp'
import { keyframes } from '@mui/system'

const Scroll = () => {
  const [showScroll, setShowScroll] = useState(false)

  // Scroll to the top function
  const scrollToTop = () => {
    window.scrollTo({ top: 0, behavior: 'smooth' })
  }

  // Check scroll position and update visibility state
  const checkScrollTop = () => {
    if (!showScroll && window.scrollY > 300) {
      setShowScroll(true)
    } else if (showScroll && window.scrollY <= 300) {
      setShowScroll(false)
    }
  }

  // Add and remove scroll event listener
  useEffect(() => {
    window.addEventListener('scroll', checkScrollTop)
    return () => {
      window.removeEventListener('scroll', checkScrollTop)
    }
  }, [showScroll])

  // Hover animation effect (scale up on hover)
  const hoverAnimation = {
    '&:hover': {
      transform: 'scale(1.3)',
      transition: 'transform 0.3s ease-in-out',
    },
  }

  // Fade in animation when scrolling down
  const fadeIn = keyframes`
    from {
      opacity: 0;
    }
    to {
      opacity: 1;
    }
  `

  // Fade out animation when scrolling up
  const fadeOut = keyframes`
    from {
      opacity: 1;
    }
    to {
      opacity: 0;
    }
  `

  return (
    <Box>
      {/* Scroll-to-Top Button */}
      {showScroll && (
        <KeyboardArrowUpIcon
          onClick={scrollToTop}
          sx={{
            position: 'fixed',
            bottom: '40px',
            left: '50%',
            transform: 'translateX(-50%)', // Center horizontally
            fontSize: '3rem', // Larger size
            color: 'secondary.main',
            cursor: 'pointer',
            zIndex: 1000, // Stay on top
            animation: `${showScroll ? fadeIn : fadeOut} 0.5s ease`,
            ...hoverAnimation,
          }}
        />
      )}
    </Box>
  )
}

export default Scroll
