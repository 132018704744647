import React, { useEffect, useRef } from 'react'
import Box from '@mui/material/Box'
import CssBaseline from '@mui/material/CssBaseline'
import { styled } from '@mui/system'

const Container = styled(Box)({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  width: '80%',
  height: '80vh',
  position: 'relative',
  overflow: 'hidden',
})

const Icon = styled('img')({
  position: 'absolute',
  objectFit: 'contain',
  borderRadius: '50%',
  transition: 'transform 0.1s linear',
})

const IconCloud = ({ logoPaths }) => {
  const containerRef = useRef(null)
  const iconsRef = useRef([])

  useEffect(() => {
    const container = containerRef.current
    if (!container) return

    const { width, height } = container.getBoundingClientRect()
    const iconSize = 50 // Size of each icon

    iconsRef.current = logoPaths.map(() => ({
      x: Math.random() * (width - iconSize) - (width - iconSize) / 2,
      y: Math.random() * (height - iconSize) - (height - iconSize) / 2,
      z: Math.random() * 200 - 100,
      vx: Math.random() * 0.2 - 0.1,
      vy: Math.random() * 0.2 - 0.1,
      vz: Math.random() * 0.2 - 0.1,
    }))

    const checkCollision = (icon1, icon2) => {
      const dx = icon1.x - icon2.x
      const dy = icon1.y - icon2.y
      const dz = icon1.z - icon2.z
      const distance = Math.sqrt(dx * dx + dy * dy + dz * dz)
      return distance < iconSize
    }

    const resolveCollision = (icon1, icon2) => {
      const tempVx = icon1.vx
      const tempVy = icon1.vy
      const tempVz = icon1.vz
      icon1.vx = icon2.vx
      icon1.vy = icon2.vy
      icon1.vz = icon2.vz
      icon2.vx = tempVx
      icon2.vy = tempVy
      icon2.vz = tempVz
    }

    const animate = () => {
      iconsRef.current.forEach((icon, index) => {
        icon.x += icon.vx
        icon.y += icon.vy
        icon.z += icon.vz

        // Boundary check
        if (
          icon.x < -width / 2 + iconSize / 2 ||
          icon.x > width / 2 - iconSize / 2
        )
          icon.vx *= -1
        if (
          icon.y < -height / 2 + iconSize / 2 ||
          icon.y > height / 2 - iconSize / 2
        )
          icon.vy *= -1
        if (icon.z < -100 || icon.z > 100) icon.vz *= -1

        // Collision detection and response
        for (let i = index + 1; i < iconsRef.current.length; i++) {
          if (checkCollision(icon, iconsRef.current[i])) {
            resolveCollision(icon, iconsRef.current[i])
          }
        }

        const iconElement = container.children[index]
        if (iconElement) {
          iconElement.style.transform = `translate3d(${icon.x}px, ${icon.y}px, ${icon.z}px)`
        }
      })

      requestAnimationFrame(animate)
    }

    animate()
  }, [logoPaths])

  return (
    <Box
      sx={{
        width: '100%',
        height: '100%',
        pt: 5,
        justifyContent: 'center',
        alignItems: 'center',
      }}
    >
      <CssBaseline />
      <Container
        ref={containerRef}
        sx={{
          justifyContent: 'center',
          alignItems: 'center',
          direction: 'ltr', // Override the direction here if needed
        }}
      >
        {logoPaths.map((src, index) => (
          <Icon
            key={index}
            src={src}
            alt={`Logo ${index + 1}`}
            sx={{
              width: '80px',
              height: '80px',
              transform: 'none', // Ensure it respects your custom transform
            }}
          />
        ))}
      </Container>
    </Box>
  )
}

export default IconCloud
