import React, { useState, useEffect, useRef } from 'react'
import { Avatar, Box, Card, CardContent, Typography } from '@mui/material'
import { useTranslation } from 'react-i18next'
import { keyframes } from '@mui/system'

export function SlidingIcons({ icons }) {
  const { t, i18n } = useTranslation()
  const isArabic = i18n.language === 'ar'

  const [mounted, setMounted] = useState(false)
  const containerRef = useRef(null) // Reference to the scrollable container
  const [isDragging, setIsDragging] = useState(false) // To track dragging state
  const [startX, setStartX] = useState(0) // Store starting X position of drag
  const [scrollLeft, setScrollLeft] = useState(0) // Store the scroll position

  useEffect(() => {
    setMounted(true)
  }, [])

  const handleMouseDown = e => {
    setIsDragging(true)
    setStartX(e.clientX)
    setScrollLeft(containerRef.current.scrollLeft)
  }

  const handleMouseMove = e => {
    if (!isDragging) return

    const xDiff = e.clientX - startX
    containerRef.current.scrollLeft = scrollLeft - xDiff

    // Loop the scroll position if it reaches the edge
    const containerWidth =
      containerRef.current.scrollWidth - containerRef.current.clientWidth
    if (containerRef.current.scrollLeft <= 0) {
      containerRef.current.scrollLeft = containerWidth // Reset to the farthest right
    }
    if (containerRef.current.scrollLeft >= containerWidth) {
      containerRef.current.scrollLeft = 0 // Reset to the farthest left
    }
  }

  const handleMouseUp = () => {
    setIsDragging(false)
  }

  const handleMouseLeave = () => {
    if (isDragging) {
      setIsDragging(false)
    }
  }

  if (!mounted) {
    return null
  }

  // Define the scrolling animation using keyframes
  const scrollAnimation = keyframes`
    0% {
      transform: translateX(0);
    }
    100% {
      transform: translateX(-50%); /* Move the container to the left by 50% */
    }
  `

  return (
    <Box>
      <Typography variant="h2" align="center" gutterBottom>
        {t('about_us.clients')}
      </Typography>

      <Box
        sx={{
          position: 'relative',
          height: 200, // Adjusted height for cards
          width: '100%', // Full width for the container
          overflow: 'hidden', // Hide anything that overflows
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          borderRadius: 2,
          border: 1,
          borderColor: 'grey.300',
          backgroundColor: 'background.default',
          boxShadow: 3,
          mt: 4,
          cursor: isDragging ? 'grabbing' : 'grab', // Change cursor during drag
        }}
        ref={containerRef}
        onMouseDown={handleMouseDown}
        onMouseMove={handleMouseMove}
        onMouseUp={handleMouseUp}
        onMouseLeave={handleMouseLeave}
      >
        {/* Scrolling container */}
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            animation: isDragging
              ? 'none'
              : `${scrollAnimation} 360s linear infinite`, // Apply animation when not dragging
          }}
        >
          {/* First round of icons */}
          {icons.map((icon, index) => (
            <Card
              key={index}
              sx={{
                margin: 2,
                minWidth: 120,
                borderRadius: 2,
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
                alignItems: 'center',
              }}
            >
              <CardContent sx={{ textAlign: 'center' }}>
                <Avatar
                  src={icon}
                  sx={{ width: 60, height: 60, marginBottom: 1 }}
                />
              </CardContent>
            </Card>
          ))}

          {/* Duplicate the items for continuous scrolling */}
          {icons.map((icon, index) => (
            <Card
              key={`duplicate-${index}`}
              sx={{
                margin: 2,
                minWidth: 120,
                borderRadius: 2,
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
                alignItems: 'center',
              }}
            >
              <CardContent sx={{ textAlign: 'center' }}>
                <Avatar
                  src={icon}
                  sx={{ width: 60, height: 60, marginBottom: 1 }}
                />
              </CardContent>
            </Card>
          ))}
        </Box>
      </Box>
    </Box>
  )
}
