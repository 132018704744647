import { useState, useEffect } from 'react'
import { getTextDirection } from '../utils/getTextDirection'
import i18n from '../i18n' // Import your i18n configuration

const useDirection = () => {
  const [direction, setDirection] = useState(getTextDirection(i18n.language))

  useEffect(() => {
    const handleLanguageChange = () => {
      setDirection(getTextDirection(i18n.language))
    }

    i18n.on('languageChanged', handleLanguageChange)

    return () => {
      i18n.off('languageChanged', handleLanguageChange)
    }
  }, [])

  return direction
}

export default useDirection
