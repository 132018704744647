// src/components/Contact.js
import React from 'react'
import { Box, Button, Container, Typography } from '@mui/material'
import ImgRight from '../common/ImgRight'
import LeaningImages from './EInvoice/serviceDetailsTwo'
import ServiceDetails from './EInvoice/ServiceDetails'
import Prices from './EInvoice/Prices'
import zatca from '../../assests/img/eInvoice/ZATCA_Logo.svg.png'
import { useTranslation } from 'react-i18next'

function Home() {
  const { t, i18n } = useTranslation()
  const isArabic = i18n.language === 'ar'
  const data = {
    title: t('einvoice.main_title'),
    img: zatca,
    text: t('einvoice.main_para'),
  }
  return (
    <Box mt="72px">
      <ImgRight text={data.text} imgsrc={data.img} title={data.title} />
      <LeaningImages />
      {/* <ServiceDetails /> */}
      <Prices />
    </Box>
  )
}

export default Home
