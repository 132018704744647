import React from 'react'
import {
  Box,
  Card,
  CardContent,
  Typography,
  CardMedia,
  useMediaQuery,
  useTheme,
} from '@mui/material'
import Analytics from '../../../assests/img/products/CRM/Analytics.jpg'
import Automation from '../../../assests/img/products/CRM/Automation.jpg'
import ClientsManagement from '../../../assests/img/products/CRM/ClientsManagement.jpg'
import CustomerSupport from '../../../assests/img/products/CRM/CustomerSupport.jpg'
import EmployeesManagement from '../../../assests/img/products/CRM/EmployeesManagement.jpg'
import LeadsTracking from '../../../assests/img/products/CRM/LeadsTracking.png'
import MarketingActivities from '../../../assests/img/products/CRM/MarketingActivities.jpg'
import MarketYourBusiness from '../../../assests/img/products/CRM/MarketYourBusiness.jpg'
import VendorsManagement from '../../../assests/img/products/CRM/VendorsManagement.jpg'
import { useTranslation } from 'react-i18next'
import { getTextDirection } from '../../../utils/getTextDirection'

const CustomTimeline = () => {
  const theme = useTheme()
  const isLarge = useMediaQuery(theme.breakpoints.down('lg'))
  const { t, i18n } = useTranslation()
  const direction = getTextDirection(i18n.language)
  const events = [
    {
      id: 1,
      text: t('products.market_title'),
      details: t('products.market_para'),

      image: MarketYourBusiness,
    },
    {
      id: 2,
      text: t('products.sales_title'),
      details: t('products.sales_para'),
      image: Automation,
    },
    {
      id: 3,
      text: t('products.leads_title'),
      details: t('products.leads_para'),
      image: LeadsTracking,
    },
    {
      id: 4,
      text: t('products.marketing_title'),
      details: t('products.marketing_para'),

      image: MarketingActivities,
    },
    {
      id: 5,
      text: t('products.customer_support_title'),
      details: t('products.customer_support_para'),

      image: CustomerSupport,
    },
    {
      id: 6,
      text: t('products.clients_management_title'),
      details: t('products.clients_management_para'),

      image: ClientsManagement,
    },
    {
      id: 7,
      text: t('products.vendors_management_title'),
      details: t('products.vendors_management_para'),
      image: VendorsManagement,
    },
    {
      id: 8,
      text: t('products.employees_management_title'),
      details: t('products.employees_management_para'),
      image: EmployeesManagement,
    },
    {
      id: 9,
      text: t('products.analytics_title'),
      details: t('products.analytics_para'),

      image: Analytics,
    },
  ]
  return (
    <>
      {!isLarge ? (
        <Box sx={{ margin: 'auto', padding: { xs: 2, md: 4 } }}>
          <Box sx={{ position: 'relative' }}>
            <Box
              sx={{
                position: 'absolute',
                left: '50%',
                transform: 'translateX(-50%)',
                width: 2,
                height: '100%',
                backgroundColor: 'grey.300',
              }}
            />
            {events.map((event, index) => (
              <Box
                key={event.id}
                sx={{
                  display: 'flex',
                  flexDirection: {
                    xs: 'column',
                    md: index % 2 === 0 ? 'row-reverse' : 'row',
                  },
                  alignItems: 'center',
                  justifyContent: 'space-between',
                  marginBottom: { xs: 2, md: 4 },
                  textAlign: { xs: 'center', md: 'left' },
                }}
              >
                <Box
                  sx={{
                    width: 'auto',
                    height: { lg: 240, xl: 420 },
                    backgroundColor: 'primary.main',
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    zIndex: 1,
                    order: { xs: 0, md: index % 2 === 0 ? 0 : 0 },

                    mr: { xs: 0, md: index % 2 === 0 ? '10%' : 0 },
                    ml: { xs: 0, md: index % 2 === 0 ? 0 : '10%' },
                  }}
                >
                  <img
                    src={event.image}
                    alt={event.text}
                    style={{
                      width: '100%',
                      height: '100%',
                    }}
                  />
                </Box>
                <Card
                  sx={{
                    width: { xs: '100%', md: '45%' },
                    marginTop: { xs: 2, md: 0 },
                    [index % 2 === 0 ? 'marginRight' : 'marginLeft']: {
                      md: 'auto',
                    },
                    padding: { xs: 2, md: 3 },
                  }}
                >
                  <CardContent>
                    <Typography
                      variant="h2"
                      gutterBottom
                      align={direction === 'rtl' ? 'right' : 'left'}
                    >
                      {event.text}
                    </Typography>
                    <Box
                      sx={{
                        display: 'flex',
                        alignItems: 'center',
                        marginBottom: 2,
                        flexDirection: { xs: 'column', sm: 'row' },
                      }}
                    >
                      <Typography
                        variant="body2"
                        align={direction === 'rtl' ? 'right' : 'left'}
                      >
                        {event.details}
                      </Typography>
                    </Box>
                  </CardContent>
                </Card>
              </Box>
            ))}
          </Box>
        </Box>
      ) : (
        <Box sx={{ maxWidth: 'lg', mx: 'auto', px: 4, py: 8 }}>
          <Box sx={{ position: 'relative' }}>
            <Box
              sx={{
                position: 'absolute',
                left: '50%',
                transform: 'translateX(-50%)',
                width: '2px',
                height: '100%',
                backgroundColor: 'grey.300',
              }}
            />
            {events.map((event, index) => (
              <Box key={event.id} sx={{ position: 'relative', mb: 4 }}>
                <Box
                  sx={{
                    display: 'flex',
                    flexDirection: {
                      xs: 'column',
                      md: index % 2 === 0 ? 'row' : 'row-reverse',
                    },
                    alignItems: 'center',
                  }}
                >
                  <Box
                    sx={{
                      flexShrink: 0,
                      width: { xs: 96, md: 160 },
                      height: { xs: 96, md: 160 },
                      backgroundColor: 'primary.main',
                      borderRadius: '50%',
                      overflow: 'hidden',
                      zIndex: 10,
                    }}
                  >
                    <img
                      src={event.image}
                      alt={event.text}
                      width={240}
                      height={240}
                      style={{
                        width: '100%',
                        height: '100%',
                        objectFit: 'cover',
                      }}
                    />
                  </Box>
                  <Card
                    sx={{
                      mt: { xs: 2, md: 0 },
                      ml: { md: index % 2 === 0 ? 2 : 0 },
                      mr: { md: index % 2 !== 0 ? 2 : 0 },
                      width: '100%',
                      // maxWidth: 'calc(100% - 11rem)',
                    }}
                  >
                    <CardContent sx={{ p: 2 }}>
                      <Typography variant="h2" sx={{ mb: 1 }}>
                        {event.text}
                      </Typography>
                      <Typography variant="body2">{event.details}</Typography>
                    </CardContent>
                  </Card>
                </Box>
              </Box>
            ))}
          </Box>
        </Box>
      )}
    </>
  )
}

export default CustomTimeline
