// src/components/Contact.js
import React from 'react'
import { Box } from '@mui/material'

import Main from './ContactUsComponents/Main.js'
import NeedHelp from './ContactUsComponents/NeedHelp.js'
function ContactUs() {
  return (
    <Box>
      <Main />
      <NeedHelp />
    </Box>
  )
}

export default ContactUs
