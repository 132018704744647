import React from 'react'
import { Box, Card, CardContent, Typography, keyframes } from '@mui/material'
import miniIcon from '../../../assests/img/products/miniIcon.png'
import { getTextDirection } from '../../../utils/getTextDirection'

import { useTranslation } from 'react-i18next'
const pulse = keyframes`
  0% {
    transform: scale(1);
  }
  50% {
    transform: scale(1.05);
  }
  100% {
    transform: scale(1);
  }
`

const rotate = keyframes`
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
`

const shimmer = keyframes`
  0% {
    background-position: -1000px 0;
  }
  100% {
    background-position: 1000px 0;
  }
`

const ERPConsulationCard = () => {
  const { t, i18n } = useTranslation()
  const direction = getTextDirection(i18n.language)
  const cards = [
    {
      title: t('services.process_consulting'),
      content: [
        t('services.digital_strategy'),
        t('services.change_management'),
        t('services.financial_restructuring'),
        t('services.business_process_improvements'),
      ],
    },
    {
      title: t('services.technical_consulting'),
      content: [
        t('services.erp_customization'),
        t('services.technology_assessment'),
        t('services.module_selection_integration'),
        t('services.merger_Acquisition_Integration'),
        t('services.enterprise_software_implementation'),
      ],
    },
  ]

  return (
    <Box
      sx={{
        display: 'flex',
        flexWrap: 'wrap',
        gap: 2,
        justifyContent: 'center',
        p: 2,
      }}
    >
      {cards.map((card, index) => (
        <Card
          key={index}
          sx={{
            display: 'flex',
            flexDirection: 'column',
            // justifyContent: 'center',
            //alignItems: 'center',
            //textAlign: 'center',
            margin: 3,
            animation: `${pulse} 2s infinite`,
            sx: {
              bgcolor: 'primary.light',
              color: 'primary.contrastText',
              transition: 'all 0.3s ease-in-out',
              '&:hover': {
                transform: 'translateY(-10px)',
                boxShadow: theme => theme.shadows[10],
              },
            },
          }}
        >
          <CardContent m="1">
            <Typography
              variant="h5" // Adjust font size for better visibility
              sx={{
                color: 'secondary.main',
                fontWeight: 'bold',
                textAlign: 'center',
                mt: 1,
                mb: 1,
              }}
            >
              {card.title}
            </Typography>
            {card.content.map((item, idx) => (
              <Box
                key={idx}
                sx={{
                  display: 'flex',
                  flexDirection: direction === 'rtl' ? 'row-reverse' : 'row',
                  alignItems: 'center',
                  marginBottom: 1,
                }}
                align={direction === 'rtl' ? 'right' : 'left'}
              >
                <img
                  src={miniIcon}
                  alt="bullet"
                  style={{
                    width: '12px', // Fixed width
                    height: '12px', // Fixed height to maintain aspect ratio
                    marginRight: direction === 'rtl' ? '0' : '8px',
                    marginLeft: direction === 'rtl' ? '8px' : '0',
                  }}
                />

                <Typography
                  variant="body2"
                  align={direction === 'rtl' ? 'right' : 'left'}
                >
                  {item}
                </Typography>
              </Box>
            ))}
          </CardContent>
        </Card>
      ))}
    </Box>
  )
}

export default ERPConsulationCard
