import { Box, Typography, useTheme, useMediaQuery } from '@mui/material'
import { useTranslation } from 'react-i18next'

import React, { useState, useRef, useEffect } from 'react'
import '../../assests/styles/main.css'
import BackgroundCircle from './BackgroundCircle'
import { getTextDirection } from '../../utils/getTextDirection'
export default function ImgLeft({ title, text, imgsrc }) {
  const theme = useTheme()
  const isMobile = useMediaQuery(theme.breakpoints.down('md')) // Adjust the breakpoint
  const { t, i18n } = useTranslation()
  const isArabic = i18n.language === 'ar'
  const [isVisible, setIsVisible] = useState(false)
  const ref = useRef(null)
  const isLarge = useMediaQuery(theme.breakpoints.down('lg'))
  const isSmall = useMediaQuery(theme.breakpoints.down('sm'))
  const direction = getTextDirection(i18n.language)
  useEffect(() => {
    const observer = new IntersectionObserver(
      ([entry]) => {
        setIsVisible(entry.isIntersecting)
        // Reset slide-out when visible
        console.log(isLarge ? (isSmall ? 0.05 : 0.3) : 0.5)
      },
      { threshold: isLarge ? (isSmall ? 0.05 : 0.3) : 0.5 } // Adjust threshold as needed
    )

    if (ref.current) {
      observer.observe(ref.current)
    }

    return () => {
      if (ref.current) {
        observer.unobserve(ref.current)
      }
    }
  }, [])
  return (
    <Box ref={ref}>
      <Box // Set the ref to the Box
        align="center"
        className={isVisible ? 'slide-in-from-right' : 'slide-out-from-right'}
        sx={{
          display: 'flex',
          flexDirection: { xs: 'column', sm: 'column', md: 'row', lg: 'row' },
          //isMobile ? 'column' : 'row', // Stack on mobile
          justifyContent: 'space-between',
          alignItems: 'center',
          backgroundImage: {
            sx: 'none',
            sm: 'none',
            md: `url(${imgsrc})`,
            lg: `url(${imgsrc})`,
          },
          //!isMobile ? `url(${imgsrc})` : 'none', // Background image for larger screens
          backgroundPosition: 'left',
          backgroundRepeat: 'no-repeat',
          backgroundSize: '30%',
          // height: { xs: 'none', sm: 'none', md: 'none', lg: `none` },
          height: { md: '60vh', lg: `none` },
          ml: { md: '10%', lg: `5%` },
          //!isMobile ? '100vh' : 'none',
        }}
      >
        <Box sx={{ flex: 1 }}></Box>

        {/* Left Column: Text */}
        <Box
          sx={{
            mt: 2,
            flex: 1,
            padding: { xs: '1rem', md: '1rem', lg: `3rem` }, //isMobile ? '1rem' : '3rem',
            width: { xs: '75%', md: '50%', lg: `50%` },
            margin: { xs: '0 1rem', md: '0 2rem' },

            //  ml: { md: 5, lg: 20 },
          }}
        >
          <Typography
            variant="h2"
            component="h1"
            gutterBottom
            sx={{ pb: 3, fontWeight: 'bold' }}
          >
            {title}
          </Typography>
          {/* {t('home.business')} */}
          <Typography
            variant="body2"
            align={direction === 'rtl' ? 'right' : 'left'}
            sx={{ whiteSpace: 'pre-line' /*fontSize: '1.1rem'*/ }}
          >
            {/* {t('home.paragraph')} */}
            {text}
          </Typography>
        </Box>

        {/* <BackgroundCircle Nb={0} position={{ top: '-70vh', right: '70vw' }} /> */}

        <BackgroundCircle Nb={1} position={{ top: '0vh', right: '50vw' }} />

        <BackgroundCircle Nb={1} position={{ top: '-65vh', left: '-25vw' }} />
        {/* Right Column: Image */}
        {!isMobile ? (
          <Box></Box>
        ) : (
          <Box
            sx={{
              width: '100%',
              mt: 4,
              mb: 4,
              alignContent: 'center',
              minHeight: '200px', // Ensures space is reserved for the image
              position: 'relative', // Enables positioning adjustments
            }}
          >
            <img
              src={imgsrc} // Image for mobile
              alt="Mobile App Showcase"
              style={{
                width: '100%',
                maxWidth: '100%',
                marginBottom: 4,
                height: 'auto',
              }} // Responsive styling
            />
          </Box>
        )}
      </Box>
    </Box>
  )
}
