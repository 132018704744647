import React, { useState } from 'react'
import {
  Box,
  Typography,
  TextField,
  Button,
  TextareaAutosize,
} from '@mui/material'
import { useTheme } from '@mui/material'
import { useTranslation } from 'react-i18next'
import PhoneIcon from '@mui/icons-material/Phone'
import LocationOnIcon from '@mui/icons-material/LocationOn'
import EmailIcon from '@mui/icons-material/Email'
import phone from '../../../assests/img/contactUs/needHelp.png'

import BackgroundCircle from '../../common/BackgroundCircle'
export default function NeedHelp() {
  const theme = useTheme()
  const { t, i18n } = useTranslation()
  const isArabic = i18n.language === 'ar'
  const LabelColor = theme.palette.secondary.main
  const TextFieldColor = theme.palette.background.default
  const LabelTextFieldColor = theme.palette.secondary.main
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    companyName: '',
    phone: '',
    country: '',
    message: '',
  })

  const handleChange = e => {
    setFormData({ ...formData, [e.target.name]: e.target.value })
  }

  const handleSubmit = () => {
    const { name, email, companyName, phone, country, message } = formData
    console.log(formData)
    const mailtoLink = `mailto:mody.k@hotmail.com?subject=Contact%20Form%20Submission&body=Name: ${name}%0AEmail: ${email}%0ACompany: ${companyName}%0APhone: ${phone}%0ACountry: ${country}%0AMessage: ${message}`
    window.location.href = mailtoLink
  }
  return (
    <Box
      sx={{
        mt: '10vh',
        maxWidth: { md: '100%', lg: '100%' },
        padding: 1,
        display: 'grid',
        gridTemplateColumns: {
          xs: '1fr',
          md: '1fr',
          lg: '2fr 2fr',
        },
        gap: 2,
      }}
    >
      {/* Second Row: Image */}
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'center',
          mt: '10%',
          mb: '10%',
        }}
      >
        <img
          src={phone}
          alt="Mobile App Showcase"
          style={{
            width: '80vw',

            maxWidth: '30vw',
            height: 'auto',
          }}
        />
      </Box>
      <Box
        sx={{
          display: 'flex',
          flexDirection: {
            md: 'column',
            lg: 'row',
          },
          alignItems: 'center',
          justifyContent: 'space-between',

          // padding: {
          //   md: '2rem',
          //   lg: '4rem',
          // },
        }}
      >
        {/* <BackgroundCircle Nb={0} position={{ top: '-30vh', right: '33vw' }} />
        <BackgroundCircle Nb={1} position={{ top: '0vh', left: '35vw' }} />
        <BackgroundCircle Nb={1} position={{ top: '20vh', right: '5vw' }} /> */}
        <Box
          sx={{
            flex: 1,
            pr: { md: 0, lg: '2rem' },
            textAlign: 'center',
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'center',
            width: { md: '90vw', lg: '40vw' },
          }}
        >
          <Box
            display="flex"
            flexDirection="column"
            alignItems="center"
            justifyContent="center"
            sx={{
              margin: 'auto',
              p: '2rem',
              backgroundColor: theme.palette.background.default,
              borderRadius: '10px',
              boxShadow: '0 4px 12px rgba(0, 0, 0, 0.1)',
              width: '80%',
            }}
          >
            <Typography
              variant="h3"
              color={LabelTextFieldColor}
              fontWeight="bold"
              gutterBottom
            >
              {t('contact_us.contact_us')}
            </Typography>
            <Typography
              variant="body1"
              color={theme.palette.secondary.main}
              gutterBottom
            >
              {t('contact_us.contact_us_para')}
            </Typography>

            <TextField
              label={t('contact_us.label_your_name')}
              name="name"
              variant="outlined"
              fullWidth
              value={formData.name}
              onChange={handleChange}
              sx={{
                mb: '1rem',
                '& .MuiOutlinedInput-root': {
                  borderRadius: '50px',
                  backgroundColor: TextFieldColor,
                },
                '& .MuiInputLabel-root': { color: LabelColor },
              }}
            />
            <TextField
              label={t('contact_us.label_email')}
              name="email"
              variant="outlined"
              fullWidth
              value={formData.email}
              onChange={handleChange}
              sx={{
                mb: '1rem',
                '& .MuiOutlinedInput-root': {
                  borderRadius: '50px',
                  backgroundColor: TextFieldColor,
                },
                '& .MuiInputLabel-root': { color: LabelColor },
              }}
            />
            <TextField
              label={t('contact_us.label_company_name')}
              name="companyName"
              variant="outlined"
              fullWidth
              value={formData.companyName}
              onChange={handleChange}
              sx={{
                mb: '1rem',
                '& .MuiOutlinedInput-root': {
                  borderRadius: '50px',
                  backgroundColor: TextFieldColor,
                },
                '& .MuiInputLabel-root': { color: LabelColor },
              }}
            />
            <TextField
              label={t('contact_us.label_phone')}
              name="phone"
              variant="outlined"
              fullWidth
              value={formData.phone}
              onChange={handleChange}
              sx={{
                mb: '1rem',
                '& .MuiOutlinedInput-root': {
                  borderRadius: '50px',
                  backgroundColor: TextFieldColor,
                },
                '& .MuiInputLabel-root': { color: LabelColor },
              }}
            />
            <TextField
              label={t('contact_us.label_country')}
              name="country"
              variant="outlined"
              fullWidth
              value={formData.country}
              onChange={handleChange}
              sx={{
                mb: '1rem',
                '& .MuiOutlinedInput-root': {
                  borderRadius: '50px',
                  backgroundColor: TextFieldColor,
                },
                '& .MuiInputLabel-root': { color: LabelColor },
              }}
            />
            <TextareaAutosize
              minRows={4} // Adjust this as needed
              value={formData.message}
              onChange={handleChange}
              variant="outlined"
              name="message"
              placeholder={t('contact_us.label_message')}
              style={{
                width: '100%',
                padding: '1rem',
                borderRadius: '50px',
                backgroundColor: TextFieldColor,
                border: `1px solid ${LabelColor}`,
                fontFamily: 'inherit',
                fontSize: '16px',
                marginBottom: '2rem',
                boxSizing: 'border-box',
                // Ensures padding is included in width
              }}
            />
            <Button
              variant="contained"
              color="secondary"
              sx={{
                width: '100%',
                borderRadius: '50px',
                p: '0.8rem',
                color: theme.palette.secondary.contrastText,
              }}
              onClick={e => handleSubmit()}
            >
              {t('contact_us.button_started')}
            </Button>
          </Box>
        </Box>
      </Box>
    </Box>
  )
}
